import {
	FeedService, LikeResponse, MessageRequest, MessageResponse,
	MessageService,
	PageMessageResponse, PersonVO, StatisticService, TopSenderResponse, UserService
} from 'backend/services/tangerine';

import { showError, showMessage } from 'utils/notifications';
import { IMessage, IUserData, RequestParamsWithUserData, ServerError } from 'utils/types';
import { getPassedTimeStr, lastFirstNameRender } from 'utils/utils';

export type FeedType = 'ALL' | 'FOR_ME' | 'FROM_ME' | 'MY_DEPARTMENT' | 'POPULAR' | 'TOP_SENDERS';

export function isValidFeedType(keyInput: string): keyInput is FeedType {
	return ['ALL', 'FOR_ME', 'FROM_ME', 'MY_DEPARTMENT', 'POPULAR', 'TOP_SENDERS'].includes(keyInput);
}

export interface GetTangerinesRequestParams {
	pageNum: number;
	pageSize: number;
	currentUser: IUserData;
	feedType: FeedType;
}

export interface TangerinesLoadResponse {
	feedType: FeedType,
	loadedPage: number;
	isSuccess: boolean,
	content: IMessage[],
	totalElements: number
}

export type TangerineLoadCallback = (props: TangerinesLoadResponse) => void;

export const loadTangerines = (params: GetTangerinesRequestParams, onLoad: TangerineLoadCallback) => {
	const {
		pageNum, pageSize, currentUser: { id: userId, deptPath }, feedType
	} = params;

	const requestParams: RequestParamsWithUserData = {
		pageNum,
		pageSize,
		body: {
			dept: deptPath,
			id: userId
		}
	}

	let request = null;
	if (feedType === 'ALL' || feedType === 'MY_DEPARTMENT') {
		request = feedType === 'ALL' ? FeedService.all : request = FeedService.dept;
	} else if (feedType === 'FOR_ME' || feedType === 'FROM_ME') {
		request = feedType === 'FOR_ME' ? FeedService.inbox : FeedService.outbox;
	} else if (feedType === 'POPULAR') {
		request = FeedService.popular;
	}

	if (!request) {
		return;
	}

// @ts-ignore
	request(requestParams)
		.then(({ content = [], totalElements = 0 }: PageMessageResponse) => {
			const convertedData = content.map((item, index) => {
				const isSentByMe = item.from?.id === userId;
				const myIndex = (item.recipients || []).findIndex(({ id: recipientId }) => recipientId === userId);
				const isSentToMe = myIndex > -1;
				let receivers = [...(item.recipients || [])];
				if (isSentToMe) {
					const myVO = { ...receivers[myIndex] };
					receivers.splice(myIndex, 1);
					receivers.unshift(myVO);
				}

				return {
					...item,
					timePassed: getPassedTimeStr(item.createDate),
					recipients: receivers,
					showMine: isSentToMe && feedType !== 'FOR_ME',
					isSentByMe,
					isSentToMe
				}
			});

			onLoad({
				feedType,
				loadedPage: pageNum + 1,
				isSuccess: true,
				// @ts-ignore
				content: convertedData,
				totalElements
			});
		})
		.catch((error: ServerError) => {
			showError('Не удалось загрузить мандаринки', error);
			onLoad({
				loadedPage: pageNum + 1,
				feedType,
				isSuccess: false,
				content: [],
				totalElements: 0
			});
		});
}

export const loadTangerineById = (messageId: number, user: IUserData, onLoad: (isSuccess: boolean, message?: MessageResponse) => void) => {
	const requestParams = {
		id: messageId,
		body: {
			id: user.id,
			dept: user.deptPath
		}
	};

	MessageService.getMessageById(requestParams)
		.then(msg => {
			onLoad(true, msg);
		})
		.catch(e => {
			showError('Не удалось обновить количество лайков :(');
			onLoad(false);
		});
};

export const sendTangerine = (requestParams: MessageRequest, onLoad: (isSuccess: boolean) => void) => {
	MessageService.createMessage({
		body: requestParams
	})
		.then(() => {
			showMessage('Мандаринка отправлена');
			onLoad(true);
		})
		.catch(e => {
			showError('Не удалось отправить мандаринку', e);
			onLoad(false);
		})
}

export const likeTangerine = ({
	                              messageId,
	                              user
                              }: { messageId: number, user: PersonVO }, onLoad: (isSuccess: boolean) => void) => {
	MessageService.like({
		messageId,
		body: user
	})
		.then(() => {
			onLoad(true);
		})
		.catch(e => {
			showError('Не удалось поставить лайк :(');
			onLoad(false);
		})
}

export const deleteTangerine = (userId: number, messageId: number, onLoad: (isSuccess: boolean) => void) => {
	UserService.changeStatus({ messageId, userId })
		.then(() => {
			showMessage('Сообщение удалено');
			onLoad(true);
		})
		.catch(e => {
			showError('Не получилось удалить сообщение :(');
			onLoad(false);
		})
}
