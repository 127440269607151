import React from 'react';
import AppHeader from 'layout/header';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivatePage from 'layout/app/private-route';
import Feed from 'views/feed';
import UserProfile from 'views/profile';
import UserStatistic from 'views/statistic';
import {AdminView} from "../../../views/admin";
import Archive from "../../../views/archive";


const AppLayout = ({ isUserLoggedIn,}: { isUserLoggedIn: boolean }) => {
	// const feedRef = useRef('');

	return (
		<div className={`app-container ${isUserLoggedIn ? 'with_background' : ''}`}>
			{isUserLoggedIn && <AppHeader
				isUserLoggedIn={isUserLoggedIn}
				// ref={headerRef}
			/>}
			<div className='app-viewport'>
				<Routes>
					<Route
						path='/feed'
						element={<PrivatePage><Feed  /></PrivatePage>}
					/>
					<Route
						path='/profile'
						element={<PrivatePage><UserProfile /></PrivatePage>}
					/>
					<Route
						path='/statistic'
						element={<PrivatePage><UserStatistic /></PrivatePage>}
					/>
					<Route
						path='/archive'
						element={<PrivatePage><Archive /></PrivatePage>}
					/>
					<Route
						path='/admin'
						element={<PrivatePage><AdminView /></PrivatePage>}
					/>
					<Route
						path='*'
						element={<Navigate
							replace
							to='/feed'
						/>}
					/>
				</Routes>
			</div>
		</div>
	)
};
export default AppLayout;
