/**
 * Лоадинг-маска на весь экран. Блокирует любые действия пользователя до окончания процесса
 * @param        size     Размер лоадинг-спиннера
 * @param        text     Текст для отображения возле спиннера
 *
 * @returns      {JSX.Element}
 * @constructor
 */

import React from 'react';
import styles from './styles.module.scss';
import { Spin, SpinProps } from 'antd';

interface ILoadingProps extends SpinProps {
	className?: string;
	text?: string;
	style?: React.CSSProperties
}

// @ts-ignore
const Loading = ({ className = '', size, text, style = {} }: ILoadingProps): JSX.Element => (
	// @ts-ignore
	<div
		role='button'
		onKeyDown={() => {
		}}
		className={`${styles.loadingMask} ${className}`}
		onClick={(ev) => {
			ev.preventDefault();
		}}
		{...style}
	>
		<Spin
			className='spin'
			{...{ size }}
			tip={text || ''}
		/>
	</div>
);

export default Loading;
