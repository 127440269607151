import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import data from '@emoji-mart/data';
// @ts-ignore
import Picker from '@emoji-mart/react';
import styles from './styles.module.scss';

const MAX_LENGTH = 1000;
const MIN_LENGTH = 10;

const TextareaWithEmojis = ({
	                            inputRef,
	                            value,
	                            setValue,
	disabled = false,
                            }: { inputRef: any, value: string, setValue: any,disabled?: boolean }) => {
	const [isVisibleEmojiPicker, setVisibleEmojiPicker] = useState(false);
	const isValidMessage = useMemo(() => value.length < MIN_LENGTH || value.length > MAX_LENGTH, [value.length]);

	const closePicker = useCallback(() => {
		setVisibleEmojiPicker(false);
	}, []);


	const handleDocumentClick = (event: any) => {
		const parent = document.querySelector('em-emoji-picker');
		if (parent) {
			// @ts-ignore
			const { props: { className = '' } = {} } = parent;
			const isVisiblePicker = className.includes('emoji-picker-is-open')
			if (isVisiblePicker && !parent.contains(event.target)) {
				closePicker();
			}
		}
	};

	useEffect(() => {
		const form = document.querySelector('#tangerine-form');
		if (form) {
			form.addEventListener('click', handleDocumentClick, false);
		}
	}, []);

	return (
		<div className={styles.text_area_with_emoji}>
			<div className={styles.text_area}>
				<Input.TextArea
					rows={4}
					disabled={disabled}
					ref={inputRef}
					className={isValidMessage ? styles.message_invalid : ''}
					maxLength={MAX_LENGTH}
					showCount={true}
					placeholder={`Ваше поздравление (от ${MIN_LENGTH} до ${MAX_LENGTH} символов)`}
					value={value}
					onChange={({ target: { value } }) => {
						setValue(value)
					}}
				/>
				<Button
					className={styles.emoji_btn}
					icon={<SmileOutlined />}
					onClick={() => {
						setVisibleEmojiPicker(true);
					}}
				/>
				{
					isVisibleEmojiPicker && <div
						id='picker-parent'
						style={{ position: 'absolute', right: 0, zIndex: 10 }}
					>
						<Picker
							parent={document.querySelector('#picker-parent')}
							// style={{ position: 'absolute', bottom: '20px', right: '20px' }}
							className={isVisibleEmojiPicker ? 'emoji-picker-is-open' : ''}
							emojiTooltip={true}
							skinTonePosition={'none'}
							data={data}
							onEmojiSelect={(em: any) => {
								// @ts-ignore
								setValue(prev => `${prev}${em.native}`);
							}}
						/>
					</div>
				}

			</div>
			<div className={`${styles.hint} ${isValidMessage ? styles.hint_invalid : ''}`}>от {MIN_LENGTH} до {MAX_LENGTH} символов</div>
		</div>
	);
}

export default TextareaWithEmojis;
