import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import {Modal} from 'antd';

import {getUserData} from 'backend/services/auth';
import {Person} from 'backend/services/pipl';
import AuthContext, {EMPTY_USER, EmptyProfile, EmptyStatistic} from 'context/auth-context';
import {showError} from 'utils/notifications';
import {IAuthData, IUserData, IUserProfile, ServerError} from 'utils/types';
import {ADMIN_ROLES, LOGIN_ONLY_ADMINS} from 'utils/constants';
import {setTokenToBackend} from 'layout/app/utils';
import AppLayout from 'layout/app/content';
import {SettingResponse, SettingService, TangerineProfileService} from 'backend/services/tangerine';
import rgbHex from 'rgb-hex';

export const USER_TOKEN_COOKIE_NAME = 'tangerineUserData';


const AppContent = () => {
    const [cookies, setCookie, removeCookie] = useCookies([USER_TOKEN_COOKIE_NAME]);
    const {[USER_TOKEN_COOKIE_NAME]: savedUserInfo} = cookies;

    const [user, setUser] = useState<IUserData>(EMPTY_USER);
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isCheckingToken, setIsCheckingToken] = useState<boolean>(!!savedUserInfo);
    const isUserLoggedIn = useMemo(() => !!(user.id && user.id > -1), [user.id]);

    const logout = useCallback(() => {
        Modal.destroyAll();
        setUser(EMPTY_USER);
        removeCookie(USER_TOKEN_COOKIE_NAME, {
            path: '/'
        });

        setTokenToBackend('');
    }, [removeCookie]);

    const loadTangerineProfile = useCallback((onLoad?: (profile: IUserProfile) => void) => {
        setIsLoadingProfile(true);
        TangerineProfileService.getProfile({personId: user.id || 0})
            .then(profile => {
                const profileValues = {
                    ...profile,
                    colorHex: profile.person.color ? rgbHex(profile.person.color) : 'gray'
                };
                setUser((prev) => ({
                    ...prev,
                    tangerineProfile: {...profileValues}
                }));

                setIsLoadingProfile(false);
                if (onLoad) {
                    onLoad({...profileValues});
                }
            })
            .catch(e => {
                showError('Не удалось загрузить профиль пользователя Мандаринок', e);
                setIsLoadingProfile(false);
            })
    }, [user.id]);

    const loadUserStatistic = useCallback((onFinish?: () => void) => {
        TangerineProfileService.getStatistics1({personId: user.id || 0})
            .then((stat) => {
                setUser((prev) => ({
                    ...prev,
                    statistic: stat
                }));


                if (onFinish) {
                    onFinish();
                }
            })
            .catch(e => {
                showError('Не удалось загрузить статистику пользователя', e);
            });
    }, [user.id]);

    const onSuccessLogin = useCallback((userData: Person & IAuthData) => {
        const {id, fullName, token, scope, email, isAdmin} = userData;
        const tokenStr = token && token.startsWith('Bearer') ? token : `Bearer ${token}`;
        const userInfoToSave = {id, fullName, token, scope, email, isAdmin};
        setTokenToBackend(tokenStr, logout);
        setCookie(USER_TOKEN_COOKIE_NAME, userInfoToSave, {
            path: '/',
            maxAge: 3600 * 24 * 3 // Храним токен 3 дня
        });

        setUser({
            ...userData,
            id,
            isAdmin,
            token: tokenStr,
            tangerineProfile: {...EmptyProfile},
            statistic: {...EmptyStatistic}
        });
    }, [setCookie]);

    const onGetToken = useCallback(({token, scope}: IAuthData, showErrorMsg = false) => {
        const roles = scope ? scope.split(' ') : [];
        const isAdmin = ADMIN_ROLES.some(role => roles.includes(role));
        if (LOGIN_ONLY_ADMINS && !isAdmin) {
            showError('Приложение доступно только администраторам');
        } else {
            setIsCheckingToken(true);
            getUserData(token, (isSuccess, response) => {
                setIsCheckingToken(false);
                if (isSuccess) {
                    const userData = response as Person & { currentYear: number };
                    onSuccessLogin({
                        ...userData,
                        scope,
                        id: userData.id || 0,
                        roles,
                        isAdmin,
                        token
                    });
                } else {
                    logout();
                    const error = response as ServerError;
                    const {response: {status} = {status: -1}} = error;
                    if (showErrorMsg) {
                        if (status === 401) {
                            showError('Время сессии истекло. Пожалуйста, авторизуйтесь заново');
                        } else {
                            showError('Не удалось получить профиль пользователя', error);
                        }
                    }
                }
            });
        }

    }, [logout]);

    const loadCurrentYear = useCallback(() => {
        const tokenStr = user.token && user.token.startsWith('Bearer') ? user.token : `Bearer ${user.token}`;
        // @ts-ignore
		SettingService.getSettings({headers: {Authorization: tokenStr}})
            .then(({tangerineYear}: SettingResponse) => {
                setUser(prev => ({
                    ...prev,
                    currentYear: tangerineYear
                }))
            }).catch((e: any) => {
            console.error(e);
        });
    }, [user.token])

    useEffect(() => {
        if ((user.id || 0) > 0) {
            loadTangerineProfile();
            loadUserStatistic();
        }
    }, [user.id])
    useEffect(() => {
        if (savedUserInfo && savedUserInfo.token) {
            const {token: savedToken, scope: savedScope, id} = savedUserInfo;
            onGetToken({id, token: savedToken, scope: savedScope, isAdmin: false});
        }
    }, []);

    // @ts-ignore
    return (
        <AuthContext.Provider
            value={{
                user: user,
                isCheckingToken,
                isUserLoggedIn,
                onGetToken,
                logout,
                loadTangerineProfile,
                loadUserStatistic,
				loadCurrentYear
            }}
        >
            <AppLayout isUserLoggedIn={isUserLoggedIn}/>
        </AuthContext.Provider>
    )
}

export default AppContent;
