import React, {useCallback, useContext, useMemo} from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import moment from "moment";
import {Avatar, Badge, Button, Divider, Dropdown, Space, Tooltip} from 'antd';
import {
    DownOutlined,
    DownloadOutlined,
    StarFilled,
    UserOutlined,
    LineChartOutlined,
    SettingOutlined,
    BookOutlined
} from '@ant-design/icons';
import AuthContext from 'context/auth-context';
import {LogoutIcon} from 'icons/logout';
import {getTangerinesText} from 'utils/localization';
import styles from './style.module.scss';

import {WinnerCupIcon} from 'icons/cup_award';
import {AdminIcon} from 'icons/admin';
import {OtrNewYearIcon} from 'icons/otr_new_year';
import {HoverableAvatar} from "./hoverable-avatar";
import {DonutIcon} from "../../icons/donut";


const year = moment().get('year');

const UserMenu = [
    {
        key: 'profile',
        label: 'Профиль',
        icon: <UserOutlined style={{fontSize: '16px'}}/>
    },
    {
        key: 'stat',
        label: 'Статистика',
        icon: <LineChartOutlined style={{fontSize: '16px'}}/>
    },
    {
        key: 'archive',
        label: 'Архив',
        icon: <BookOutlined style={{fontSize: '16px'}}/>
    },
    {
        key: 'admin',
        label: 'Админка',
        isAdmin: true,
        icon: <SettingOutlined style={{fontSize: '16px'}}/>
    },
    {
        key: 'logout',
        label: 'Выйти',
        icon: <LogoutIcon style={{fontSize: '16px'}}/>
    }
];

const AppHeader = React.forwardRef(({isUserLoggedIn}: { isUserLoggedIn: boolean }, ref) => {
    const {
        logout, loadUserStatistic,
        user: {
            currentYear = year,
            fullName,
            isAdmin,
            tangerineProfile: {person: {shortName, color}},
            statistic: {placeByTopSender, numberOfMessagesReceived, numberOfMessagesSent}
        },

    } = useContext(AuthContext);

    const navigate = useNavigate();

    const visibleMenuItems = useMemo(() => UserMenu.filter(({isAdmin: isAdminMenu = false}) => !isAdminMenu || isAdmin), [isAdmin]);

    const userFirstName = useMemo(() => {
        const [lastName, firstName] = fullName?.split(' ') || [];
        return firstName || fullName;
    }, [fullName]);

    // @ts-ignore
    const onUserMenuClick = useCallback(({key}) => {
        if (key === 'logout') {
            logout();
        } else if (key === 'profile') {
            navigate('/profile');
        } else if (key === 'stat') {
            navigate('/statistic');
        } else if (key === 'admin') {
            navigate('/admin');
        } else if (key === 'archive') {
            navigate('/archive');
        }
    }, []);

    return (
        <>
            <div className={styles.app_header}>
                <Badge.Ribbon text="AI" color='#f6842a' style={{top: 0, right: -32}}>
                    <div className={styles.tangerine_title}>
                        <Tooltip title='Поблагодарить разработчиков' mouseEnterDelay={0.1}>
                            <DonutIcon onClick={() => {
                                window.open('https://www.tinkoff.ru/cf/AIXU7PionzA', '__blank')
                            }}/>
                        </Tooltip>
                        <Tooltip
                            title='На главную'
                            mouseEnterDelay={0.5}
                        >

                            <NavLink to='/feed' className={styles.title}>
                                Мандаринки {currentYear}
                            </NavLink>
                        </Tooltip>
                    </div>
                </Badge.Ribbon>
                {isUserLoggedIn && (
                    <div className={styles.user_name_container}>
                        <Tooltip title={`${placeByTopSender} место в рейтинге отправителей`}>
                            <div
                                className={styles.tangerine_rank}
                                onClick={() => {
                                    navigate('/statistic');
                                }}
                            >
                                <StarFilled/>
                                <div className={styles.tangerine_count}>{placeByTopSender}</div>
                            </div>
                        </Tooltip>

                        {/*{color && <div className={styles.user_avatar}>*/}
                        {/*	{isAdmin && <Tooltip title='Админ'>*/}
                        {/*		<div className={styles.admin}><AdminIcon /></div>*/}
                        {/*	</Tooltip>}*/}
                        {/*	<Avatar style={{ backgroundColor: color }}>{shortName}</Avatar>*/}
                        {/*</div>}*/}
                        <Dropdown
                            trigger={['click']}
                            menu={{
                                onClick: onUserMenuClick,
                                items: visibleMenuItems
                            }}
                        >
                            <div className='user-name-avatar' style={{display: 'flex', alignItems: 'center'}}>
                                <span className='user-name'>{userFirstName}</span>
                                <div className={styles.user_avatar}>
                                    {isAdmin && <Tooltip title='Админ'>
                                        <div className={styles.admin}><AdminIcon/></div>
                                    </Tooltip>}
                                    <Avatar style={{backgroundColor: color}}>{shortName}</Avatar>
                                </div>
                            </div>
                            {/*<Button*/}
                            {/*	type='link'*/}
                            {/*	onClick={(e) => e.preventDefault()}*/}
                            {/*>*/}
                            {/*	<Space>*/}
                            {/*		/!*<UserOutlined />*!/*/}
                            {/*		{userFirstName}*/}
                            {/*		<DownOutlined />*/}
                            {/*	</Space>*/}
                            {/*</Button>*/}
                        </Dropdown>
                    </div>
                )}
            </div>
            <Divider style={{margin: '8px 0 12px 0'}}/>
        </>
    )
        ;
});

export default AppHeader;
