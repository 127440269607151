import Icon from '@ant-design/icons';

const OTRSvg = () => <svg
	className='otr_icon'
	viewBox='0 0 713 713'
	width='1em'
	height='1em'
	fill='currentColor'
>
	<path
		d='M441.113 295.492C472.598 285.655 500.15 273.847 531.635 265.974C567.057 258.104 606.413 256.136 643.802 256.136C691.031 258.104 671.353 210.877 661.513 187.262C614.284 75.0929 515.891 10.1551 391.917 0.314968C342.721 -3.62156 321.075 29.8331 315.172 73.1258C303.365 161.678 342.721 252.2 370.272 279.751C389.95 299.429 413.564 303.366 441.113 295.492Z'
		// fill='#F39629'
	/>
	<path
		d='M297.461 271.88C287.621 240.395 275.814 212.846 267.943 181.361C258.103 145.939 256.136 106.583 256.136 69.1917C258.103 21.9648 210.874 41.6429 187.261 51.4807C75.0925 98.7099 10.1544 197.102 0.31486 321.077C-3.62095 370.273 29.8335 391.92 73.1256 397.824C161.679 409.631 252.199 370.273 279.75 342.724C301.395 323.046 305.332 299.431 297.461 271.88Z'
		// fill='#F39629'
	/>
	<path
		d='M273.846 415.53C242.361 425.37 214.81 437.178 183.325 445.048C147.904 452.919 108.547 454.889 71.1571 454.889C23.9293 452.919 43.6083 500.148 53.4468 523.763C100.676 635.93 199.067 700.872 323.042 710.707C372.238 714.644 393.884 681.19 399.787 637.897C411.595 549.344 372.238 458.823 344.687 431.274C325.009 411.596 301.395 407.659 273.846 415.53Z'
		// fill='#F39629'
	/>
	<path
		d='M417.497 439.144C427.337 470.629 439.144 498.178 447.015 529.665C454.886 565.085 456.855 604.444 456.855 641.832C454.886 689.059 502.115 669.381 525.729 659.543C637.896 612.314 702.834 513.922 712.674 389.948C716.611 340.751 683.156 319.104 639.863 313.2C551.311 301.395 460.789 340.751 433.24 368.3C413.562 387.98 409.626 411.593 417.497 439.144Z'
		// fill='#F39629'
	/>
</svg>

export const OtrIcon = (props: any) => <Icon component={OTRSvg} {...props} />;


