import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Avatar, Button, Checkbox, Skeleton, Switch, Typography } from 'antd';

import { TangerineProfileService } from 'backend/services/tangerine';
import Loading from 'components/loading';
import AuthContext from 'context/auth-context';
import { showError, showMessage } from 'utils/notifications';
import { IUserProfile } from 'utils/types';
import useClickOutside from './useClickOutside';
import styles from './style.module.scss';
import ColorSelector, { hexToRGB } from 'views/profile/color-selector';

// const hexToRGB = (hex: any) => {
// 	let color = hex.startsWith('#') ? hex.replace('#', '') : hex;
// 	color = '0x' + color
// 	let r = color >> 16 & 0xFF
// 	let g = color >> 8 & 0xFF
// 	let b = color & 0xFF
// 	return `rgb(${r}, ${g}, ${b})`;
// }

const UserProfile = () => {
	const { user: { id, tangerineProfile }, loadTangerineProfile } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [profile, setProfile] = useState<IUserProfile>({ ...tangerineProfile });
	const [oldProfile, setOldProfile] = useState<IUserProfile>({ ...tangerineProfile });

	// @ts-ignore
	const isChangedProfile = ['colorHex', 'notificationOfReaction', 'notificationOfNewMessage'].some((fieldName) => oldProfile[fieldName] !== profile[fieldName]);


	const loadProfile = useCallback(() => {
		setLoading(true);
		loadTangerineProfile((loadedProfile) => {
			setProfile(loadedProfile);
			setOldProfile(loadedProfile);
			setLoading(false);
		});
	}, [id]);

	const saveProfile = useCallback(() => {
		setIsSaving(true);
		const rgbColor = hexToRGB(profile.colorHex);

		const dataToSave = {
			person: {
				...profile.person,
				color: rgbColor
			},
			notificationOfReaction: profile.notificationOfReaction,
			notificationOfNewMessage: profile.notificationOfNewMessage,
		}

		TangerineProfileService.upsertProfile({ body: dataToSave })
			.then(() => {
				setIsSaving(false);
				showMessage('Изменения в профиле сохранены');
				loadProfile();
			})
			.catch(e => {
				showError('Не удалось сохранить профиль пользователя', e);
				setProfile({ ...oldProfile });
				setIsSaving(false);
			});
	}, [
		id, tangerineProfile.notificationOfReaction, tangerineProfile.notificationOfNewMessage,
		tangerineProfile.colorHex, profile.notificationOfNewMessage, profile.notificationOfReaction,
		profile.colorHex
	]);

	const toggleMessageNotifications = useCallback((checked: boolean) => {
		setProfile((prev) => ({
			...prev,
			notificationOfNewMessage: checked
		}))
	}, []);

	const toggleReactionNotifications = useCallback((checked: boolean) => {
		setProfile((prev) => ({
			...prev,
			notificationOfReaction: checked
		}))
	}, []);


	useEffect(loadProfile, []);

	return <div className={styles.profile}>
		{loading ? <Skeleton /> : <div className={styles.profile_form}>
			{isSaving && <Loading />}
			<div className={styles.color}>
				<ColorSelector
					label={tangerineProfile.person.shortName || ''}
					value={profile.colorHex}
					onChangeColor={(color) => {
						setProfile(prev => ({
							...prev,
							colorHex: color
						}))
					}}
				/>
			</div>
			<div className={styles.email_notifications}>
				<Typography.Title level={5}>Email-уведомления</Typography.Title>
				<div className={styles.switch_container}>
					<Switch
						size='small'
						checked={profile.notificationOfReaction}
						onChange={toggleReactionNotifications}
					/>
					<span>Лайки</span>
				</div>
				<div className={styles.switch_container}>
					<Switch
						size='small'
						checked={profile.notificationOfNewMessage}
						onChange={toggleMessageNotifications}
					/>
					<span>Новые мандаринки</span>
				</div>
			</div>
			<Button
				// type='primary'
				disabled={isSaving || !tangerineProfile.colorHex || !isChangedProfile}
				onClick={saveProfile}
				style={{ marginTop: 30 }}
			>Сохранить изменения</Button>
		</div>}

	</div>
}

export default UserProfile;
