import {SetStateAction, useCallback, useState} from 'react';
import {VoidFunction} from "../utils/types";

export function useLoading(initialStatus = false): [boolean, VoidFunction, VoidFunction] {
	const [isLoading, setIsLoading] = useState(initialStatus);

	const showLoading = useCallback(() => {
		setIsLoading(true);
	}, []);

	const hideLoading = useCallback(() => {
		setIsLoading(false);
	}, []);

	return [isLoading, showLoading, hideLoading];
}
