import tangerineBackend, { piplBackend } from 'backend/axios';
import { showError } from 'utils/notifications';
import { ServerError } from 'utils/types';


export const setTokenToBackend = (token: string = '', logout?: () => void) => {
	if (token) {
		tangerineBackend.defaults.headers.Authorization = token;
		piplBackend.defaults.headers.Authorization = token;

		const errorInterceptor = (error: ServerError) => {
			if (error?.response?.status === 401) {
				if (logout) {
					logout();
				}

				showError('Время сессии истекло. Пожалуйста, авторизуйтесь заново');
			}

			return Promise.reject(error);
		};

		tangerineBackend.interceptors.response.use(
			(response) => response,
			errorInterceptor
		);

		piplBackend.interceptors.response.use(
			(response) => response,
			errorInterceptor
		);
	}
	else {
		delete tangerineBackend.defaults.headers.Authorization;
		delete piplBackend.defaults.headers.Authorization;
	}
}
