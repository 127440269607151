import {Avatar, Button, Popover} from 'antd';
import {LikeResponse} from 'backend/services/tangerine';
import {HeartFilled, HeartOutlined} from '@ant-design/icons';
import LikedPeople from './liked-people';
import {dateTimeRenderer} from "utils/utils";

import './styles.scss';

interface MessageFooterProps {
    className?: string;
    createDate: string,
    likes: LikeResponse;
    messageId: number;
}

export const ArchiveMessageFooter = (props: MessageFooterProps) => {
    const {
        messageId, createDate, likes: {liked, number = 0, persons = []}, className = '',
    } = props;

    const showAvatarsInLike = number > 0 && number < 4;
    return (
        <div className='archive-message-footer'>
            <div className='create-date'>{dateTimeRenderer(createDate, 'HH:mm DD.MM.YYYY')}</div>
            {number > 0 && (
                <Popover
                    trigger='contextMenu'
                    overlayClassName='liked-people-popover'
                    destroyTooltipOnHide={{keepParent: true}}
                    content={<LikedPeople messageId={messageId}/>}
                >
                    <div className={`likes-container ${liked ? 'me-liked' : ''}`}>
                        <div className='heart-icon'>{liked ? <HeartFilled/> : <HeartOutlined/>}</div>
                        {showAvatarsInLike ? (
                            <Avatar.Group size='small'>
                                {persons?.slice(0, 4).map(({id, shortName, color}) => (
                                    <Avatar key={id} style={{backgroundColor: color}}>{shortName}</Avatar>
                                ))}
                            </Avatar.Group>
                        ) : (
                            <span className='liked-number'>{number}</span>
                        )}
                    </div>
                </Popover>
            )}
        </div>
    )
}
