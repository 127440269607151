import Icon, {} from '@ant-design/icons';
import React from 'react';


const blackStyle: React.CSSProperties = {
	fill: 'none',
	stroke: 'white',
	strokeWidth: 16,
	strokeLinecap: 'round',
	strokeLinejoin: 'round'
}

const ThirdPlaceSvg = () => <svg
	viewBox='0 0 256 256'
	width='1em'
	height='1em'
	fill='currentColor'
>

	<rect
		fill='none'
		height='256'
		width='256'
	/>
	<circle
		cx='128'
		cy='128'
		r='96'
		style={{
			...blackStyle,
			fill: 'currentColor'
		}}
	/>
	<path
		d='M104,84h48l-28,40a28,28,0,1,1-19.8,47.8'
		style={blackStyle}
	/>
</svg>

export const ThirdPlaceIcon = (props: any) => <Icon component={ThirdPlaceSvg} {...props} />;


