import {useCallback, useContext, useState,ChangeEvent} from "react";
import {Button, Input, Typography} from "antd";
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons';
import AuthContext from "../../context/auth-context";
import {SettingService} from "../../backend/services/tangerine";
import {showError, showMessage} from "../../utils/notifications";

import moment from "moment";
import './styles.scss';

const nowYear = moment().get('year');
export const AdminView = () => {
    const {user: {currentYear}, loadCurrentYear} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(currentYear);

    const onChangeYear = useCallback(({target: {value}}:ChangeEvent<HTMLInputElement>) => {
        setYear(parseInt(value, 10));
    }, []);

    const changeCurrentYear = useCallback(() => {
        setLoading(true);
        SettingService.updateYear({
            id: 1,
            body: {tangerineYear:year}
        })
            .then(() => {
                setLoading(false);
                showMessage('Год изменён');
                loadCurrentYear();
            })
            .catch(e => {
                showError('Не удалось изменить "год" мандаринок', e)
                setLoading(false);
            });
    }, [year]);

    return <div className='admin-view'>
        <div className='year'>
            <div className='year-input'>
                <Typography.Text>Год для мандаринок:</Typography.Text>
                <Input value={year} onChange={onChangeYear} disabled={loading}/>
            </div>
            <Button disabled={!year || year <nowYear || year === currentYear} onClick={changeCurrentYear}>
                {loading ? <LoadingOutlined />: <SaveOutlined/>}
                Сохранить</Button>
        </div>

    </div>
}
