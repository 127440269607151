import Icon from '@ant-design/icons';

// <?xml version="1.0" ?>
// <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
// 	<defs>
// 	<style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
// </style></defs><title/>
// <g id="logout"><line class="cls-1" x1="15.92" x2="28.92" y1="16" y2="16"/><path d="M23.93,25v3h-16V4h16V7h2V3a1,1,0,0,0-1-1h-18a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1V25Z"/><line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="20"/><line class="cls-1" x1="28.92" x2="24.92" y1="16" y2="12"/><line class="cls-1" x1="24.92" x2="24.92" y1="8.09" y2="6.09"/><line class="cls-1" x1="24.92" x2="24.92" y1="26" y2="24"/></g></svg>
//
//
// <?xml version="1.0" ?><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
// 	<g><path d="M0 0h24v24H0z" fill="none"/><path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"/></g>
const style = {
	stroke: 'currentColor',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	strokeWidth: '2px'
}

const LogoutSvg = () => <svg
	viewBox='0 0 24 24'
	width='1em'
	height='1em'
	fill='currentColor'
>
	{/*@ts-ignore*/}
	<g>
		<path
			d='M0 0h24v24H0z'
			fill='none'
		/>
		<path d='M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z' />
	</g>
</svg>

export const LogoutIcon = (props: any) => <Icon component={LogoutSvg} {...props} />;


