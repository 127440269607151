import {authBackend} from 'backend/axios';
import {Jwt, Person, PiplService} from 'backend/services/pipl';
import {IAuthData, ServerError} from 'utils/types';
import {SettingResponse, SettingService} from "../tangerine";
import moment from "moment";

export interface ICredentials {
    login: string;
    password: string;
}

export interface IUserDataShort {
    name?: string;
    scope?: string;
    access_token: string;
    expires_in: number;
}


export const login = (credentials: ICredentials, onFinish: (isSuccess: boolean, userData: IAuthData | ServerError) => void): void => {
    const formData = new FormData();
    formData.append('client_id', credentials.login);
    formData.append('client_secret', credentials.password);
    formData.append('grant_type', 'client_credentials');

    authBackend.post('/oauth2/token', formData)
        .then((resp) => {
            onFinish(true, {token: resp.data.access_token, scope: resp.data.scope, id: 0, isAdmin: false});
        }).catch(error => {
        onFinish(false, error);
    });
}

export const getUserData = (token: string, onFinish: (isSuccess: boolean, data: Person | ServerError) => void) => {
    //если возвращает 401 - выкидыаем на логин
    const tokenStr = decodeURI(token);
    const savedTokenStr = tokenStr && tokenStr.startsWith('Bearer') ? tokenStr : `Bearer ${tokenStr}`;
    // @ts-ignore
    PiplService.currentPerson({}, {headers: {Authorization: savedTokenStr}})
        .then(loggedUser => {
            // @ts-ignore
            SettingService.getSettings({headers: {Authorization: savedTokenStr}})
                .then(({tangerineYear}: SettingResponse) => {
                    onFinish(true, {
                        ...loggedUser,
                        currentYear: tangerineYear
                    })
                }).catch((e: any) => {
                console.error(e);
                const currentYear = moment().get('year');
                onFinish(true, {
                    ...loggedUser,
                    currentYear
                })
            });
        })
        .catch(error => {
            onFinish(false, error);
        })

}
