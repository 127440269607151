import { Tooltip } from 'antd';
import { ArrowRightOutlined, LockFilled } from '@ant-design/icons';

import { OtrIcon } from 'icons/logo-otr';
import { IMessage } from 'utils/types';
import styles from 'views/feed/message/view/components/header/style.module.scss';


const MessageHeader = ({ message }: { message: IMessage }) => {
	const { from, public: isPublic, recipientType, recipients = [] } = message;
	return (
		<div className={`${styles.list_item_header} ${message.isSentToMe ? styles.sent_to_me : ''}`}>
			{(!isPublic || recipientType === 'TO_ALL') && <div className={styles.avatar_icon}>
				{recipientType === 'TO_ALL' ? <OtrIcon className={styles.icon_otr}/> : <LockFilled />}
			</div>
			}
			<Tooltip title={from?.fullName}>
				<span className={styles.sender}>{from?.displayName}</span>
			</Tooltip>
			<ArrowRightOutlined className={styles.arrow_icon} />
			<div className={styles.recipients_container}>
				{recipientType === 'TO_ALL' ?
					<span className={styles.receiver_all}>Всем</span>
					:
					<Tooltip
						title={<div>
							{recipients?.map(({ fullName, id }) => <div key={id}>
								<span>{fullName}</span>
								<br />
							</div>)}
						</div>}
					>
						{recipients.map(({ displayName }) => displayName).join(', ')}
					</Tooltip>
				}
			</div>
		</div>
	)
}

export default MessageHeader;
