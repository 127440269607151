import React, {useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {Button} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import qs from 'qs';

import {PersonVO} from 'backend/services/tangerine';
import InfiniteList from 'components/scrollable-list';
import AuthContext from 'context/auth-context';
import {IMessage} from 'utils/types';
import FeedFilterForm from 'views/feed/filter';
import {
    FeedType, isValidFeedType, loadTangerineById,
    loadTangerines as loadTangerinesList, TangerinesLoadResponse
} from 'views/feed/services';
import {IS_DEBUG, PAGE_OUTER_CONTENT_HEIGHT} from 'utils/constants';
import Message from './message/view';
import {showTangerineCreateModal} from './message/modal';
import styles from './style.module.scss';
import {useTotalMessages} from "../../hooks/useTotalMessages";


const PAGE_SIZE = IS_DEBUG ? 5 : 20;

const getTabNameFromQueryParams = (queryStr: string): FeedType | null => {
    const queryParams = qs.parse(queryStr.replace('?', ''));
    return queryParams.tab && isValidFeedType(queryParams.tab as FeedType) ? queryParams.tab as FeedType : null;
}

const getFeedContainerHeight = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight ||
        document.body.clientHeight;

    return windowHeight
        - PAGE_OUTER_CONTENT_HEIGHT
        - 32 // фильтр ленты
        - 50 // кнопка "обновить ленту"
}

const Feed = React.forwardRef((props, ref) => {
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const {user, loadUserStatistic} = useContext(AuthContext);
    const scrollableListRef = useRef(null);

    const [isLoadingTotals, totals] = useTotalMessages({
        userId: user.id || 0,
        year: user.currentYear || 0,
        userDepartment: user.deptPath || ''
    });

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [messages, setMessages] = useState<IMessage[]>([]);

    const [selectedFeedType, setFeedType] = useState<FeedType>(() => getTabNameFromQueryParams(location.search) || 'ALL');

    const scrollToTop = useCallback(() => {
        // @ts-ignore
        scrollableListRef.current?.scrollToTop();
    }, [scrollableListRef.current]);

    const onLoadData = useCallback((loadResponse: TangerinesLoadResponse) => {
        const {isSuccess, content, totalElements: total, loadedPage} = loadResponse;

        setLoading(false);
        if (isSuccess) {
            setMessages(prev => {
                return loadedPage === 1 ? content : prev.concat(content as IMessage[]);
            });

            if (totalElements !== total) {
                setTotalElements(total);
            }
        }
    }, []);

    const loadTangerines = useCallback((pageNum = 0) => {
        setLoading(true);
        if (pageNum > 0) {
            setCurrentPage(pageNum);
        }

        const pageToLoad = pageNum > 0 ? pageNum : currentPage;

        loadTangerinesList({
            pageNum: pageToLoad - 1,
            pageSize: PAGE_SIZE,
            currentUser: user,
            feedType: selectedFeedType
        }, onLoadData);
    }, [currentPage, selectedFeedType]);

    const reloadTangerines = useCallback(() => {
        // setCurrentPage(1);
        loadTangerines(1);
        scrollToTop();
        loadUserStatistic();
    }, [loadUserStatistic, scrollToTop, loadTangerines]);

    const showTangerineModal = useCallback((recipient?: PersonVO) => {
        showTangerineCreateModal({
            receiver: recipient || null,
            onSuccessSend: reloadTangerines,
            currentUser: user
        })
    }, [user.id]);

    const onLikesChange = useCallback((messageId: number, onFinishUpdate: () => void) => {
        loadTangerineById(messageId, user, (isSuccess, loadedMessage) => {
            if (isSuccess) {
                if (loadedMessage) {
                    setMessages((prev) => {
                        const newMessages = [...prev];
                        const changedMsgIndex = prev.findIndex(({id}) => id === messageId);
                        if (changedMsgIndex > -1) {
                            newMessages[changedMsgIndex].likes = loadedMessage.likes;
                        }

                        return newMessages;
                    });

                    onFinishUpdate();
                } else {
                    onFinishUpdate();
                }
            } else {
                onFinishUpdate();
            }
        });
    }, []);

    const onChangeFeedType = useCallback((newFeedType: FeedType) => {
        scrollToTop();
        setFeedType(newFeedType);
        setCurrentPage(1);
    }, [scrollToTop]);

    const onSendTangerineClick = useCallback(() => {
        showTangerineModal();
    }, [showTangerineModal]);

    const onReplyClick = useCallback((msgSender?: PersonVO) => {
        if (msgSender) {
            showTangerineModal(msgSender);
        }
    }, [showTangerineModal]);

    const loadMore = useCallback(() => {
        setCurrentPage(prevPage => prevPage + 1);
    }, []);

    useImperativeHandle(ref, () => ({
        onChangeFeedType
    }));

    useEffect(loadTangerines, [currentPage, selectedFeedType]);

    useEffect(() => {
        const newFeedType = getTabNameFromQueryParams(location.search);
        setSearchParams('');
        if (newFeedType && newFeedType !== selectedFeedType) {
            onChangeFeedType(newFeedType as FeedType);
        }
    }, [location.search, selectedFeedType]);

    useEffect(() => {
        setSearchParams('');
    }, []);

    return <div className={styles.feed}>
        <div className={styles.filter}>
            <FeedFilterForm
                totals={{...totals, isLoading: isLoadingTotals}}
                user={user}
                feedType={selectedFeedType}
                onSendMessageClick={onSendTangerineClick}
                onChangeFeedType={onChangeFeedType}
            />
        </div>
        <div className={styles.reload_btn_container}>
            {loading ? <LoadingOutlined/> : <Button
                type='link'
                className={styles.reload_btn}
                disabled={loading}
                onClick={reloadTangerines}
            >обновить ленту</Button>}
        </div>
        <InfiniteList
            getHeight={getFeedContainerHeight}
            ref={scrollableListRef}
            data={messages}
            total={totalElements}
            loading={loading}
            loadMore={loadMore}
            renderItem={(item: IMessage) => (
                <Message
                    key={item.id}
                    highlightInboxMessages={!loading && selectedFeedType !== 'FOR_ME'}
                    message={item}
                    currentUser={user}
                    onReplyClick={onReplyClick}
                    onLikesChange={onLikesChange}
                    reloadFeed={reloadTangerines}
                />
            )}
        />
    </div>
});

export default Feed;
