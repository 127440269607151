// <?xml version="1.0" ?><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title/>

import Icon from '@ant-design/icons';

// <?xml version="1.0" ?><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title/><g data-name="79-users" id="_79-users"><circle class="cls-1" cx="16" cy="13" r="5"/><path class="cls-1" d="M23,28A7,7,0,0,0,9,28Z"/><path class="cls-1" d="M24,14a5,5,0,1,0-4-8"/><path class="cls-1" d="M25,24h6a7,7,0,0,0-7-7"/><path class="cls-1" d="M12,6a5,5,0,1,0-4,8"/><path class="cls-1" d="M8,17a7,7,0,0,0-7,7H7"/></g></svg>
const style = {
	fill: 'none',
	stroke: 'currentColor',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	strokeWidth: 2
}


const PeopleSvg = () => <svg
	viewBox='0 0 32 32'
	width='1em'
	height='1em'
	fill='currentColor'
>
	<g data-name='79-users'>
		<circle
			//@ts-ignore
			style={style}
			cx='16'
			cy='13'
			r='5'
		/>
		<path
			//@ts-ignore
			style={style}
			d='M23,28A7,7,0,0,0,9,28Z'
		/>
		{/*@ts-ignore**/}
		<path
			//@ts-ignore
			style={style}
			d='M24,14a5,5,0,1,0-4-8'
		/>

		<path
			//@ts-ignore
			style={style}
			d='M25,24h6a7,7,0,0,0-7-7'
		/>
		{/*@ts-ignore**/}
		<path
			//@ts-ignore
			style={style}
			d='M12,6a5,5,0,1,0-4,8'
		/>
		{/*@ts-ignore**/}
		<path
			//@ts-ignore
			style={style}
			d='M8,17a7,7,0,0,0-7,7H7'
		/>
	</g>
</svg>

export const PeopleIcon = (props: any) => <Icon component={PeopleSvg} {...props} />;


