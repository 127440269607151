import 'antd/dist/reset.css';
import 'moment/locale/ru';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import ruRU from 'antd/locale/ru_RU';

import AppContent from 'layout/app';
import './App.scss';
import {DonutIcon} from "./icons/donut";

const App = () => {
    return (
        <ConfigProvider locale={ruRU} theme={{
            token: {
                colorPrimary: 'rgb(53, 140, 121)',
                colorLink: 'rgb(33, 69, 120)',
                colorLinkActive: 'rgb(56, 107, 178)',
                colorLinkHover: 'rgb(56, 107, 178)',
            },
        }}>
            <BrowserRouter>
                <div
                    className='App'
                    style={{backgroundImage: 'url(\'/back_2024.png\')'}}
                >
                    <AppContent/>
                </div>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;
