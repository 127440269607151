import axios from 'axios';

import { serviceOptions as tangerineBackendOptions } from 'backend/services/tangerine';
import { serviceOptions as piplBackendOptions } from 'backend/services/pipl';

const tangerineBackend = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL
});

export const authBackend = axios.create({
	baseURL: process.env.REACT_APP_AUTH_URL
})

export const piplBackend = axios.create({
	baseURL: process.env.REACT_APP_PIPL_URL
})

tangerineBackendOptions.axios = tangerineBackend;
piplBackendOptions.axios = piplBackend;


export default tangerineBackend;
