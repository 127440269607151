import React, { useCallback, useMemo } from 'react';
import { Checkbox, Tooltip } from 'antd';
import UserSelect from 'components/user-select';
import { ISelectOption } from 'utils/types';
import styles from './styles.module.scss';
import { PersonVO } from 'backend/services/tangerine';

const MAX_PEOPLE = 10;

type SimpleObject = {
	[key: string]: string | number
}
export type ChangeHandlerType = {
	[key: string]: boolean | PersonVO[]
}

interface RecipientSelectorProps {
	currentUserId: number,
	defaultValue?: ISelectOption[],
	value: PersonVO[],
	toAll: boolean,
	isPrivate: boolean,
	onChange: (changedObj: ChangeHandlerType) => void

}

const RecipientSelector = (props: RecipientSelectorProps) => {
	const { value, toAll, isPrivate, defaultValue = [], onChange, currentUserId } = props;

	const onSelectUser = useCallback((selectedUserIds: number[], selectedUsers: any[]) => {
		const changeObject: ChangeHandlerType = {
			users: selectedUsers.map(({ fullrecord }: any) => {
				const { id, fullName, email, deptPath } = JSON.parse(fullrecord);
				return { id, fullName, email, deptPath }
			})
		}
		if (selectedUsers.length > 1) {
			// @ts-ignore
			changeObject.private = false;
		}

		onChange(changeObject);
	}, [onChange]);

	const isValidSelector = useMemo(() => {
		return toAll || !!value.length;
	}, [toAll, isPrivate, value.length])

	return (
		<div className={`${styles.user_select_container} ${isValidSelector ? '' : styles.invalid}`}>
			<UserSelect
				maxValues={MAX_PEOPLE}
				optionLabelProp='shortName'
				mode='multiple'
				disabled={toAll}
				// maxTagCount='responsive'
				style={{ width: '100%' }}
				placeholder={`выберите получателей (не более ${MAX_PEOPLE})`}
				isDisabledOption={({ value: userId }) => userId === currentUserId}
				defaultValue={defaultValue}
				//@ts-ignore
				onSelect={onSelectUser}
			/>
			{value.length > 1 ? (
				<div className={`${styles.max_recipients_hint} ${value.length === MAX_PEOPLE ? styles.is_max : ''}`}>
					не более {MAX_PEOPLE} человек
				</div>
			) : <div className='private-checkbox'>
				<Tooltip title='Видно только получателю и отправителю'>
					<Checkbox
						checked={isPrivate}
						disabled={toAll || value.length > 1}
						onChange={({ target: { checked } }) => {
							onChange({
								private: checked
							});
						}}
					>Приватное</Checkbox>
				</Tooltip>
			</div>
			}
		</div>
	)
}

export default RecipientSelector;
