import Icon from '@ant-design/icons';

// <?xml version="1.0" ?><svg class="feather feather-award" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
// 	<circle cx="12" cy="8" r="7"/><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"/>
// 	</svg>

const style = {
	stroke: 'currentColor',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	strokeWidth: '2px'
}
// a = <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#4d4d4d;}</style></defs><title/>
// a = <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
const MandarinSvg = () => <svg
	viewBox='40 -40 520 520'
	width='1em'
	height='1em'
	fill='currentColor'
	// stroke='currentColor'
	// strokeLinecap='round'
	// strokeLinejoin='round'
	// strokeWidth={1}
>
	<g
		transform='translate(0.000000,512.000000) scale(0.120000,-0.120000)'
		fill='currentColor'
		stroke='none'
	>
		<rect width="24" height="24" rx="2" />
		<path
			d='M2440 4524 c-211 -41 -376 -126 -506 -261 l-50 -53 -91 0 c-482 0
-873 -390 -873 -871 l0 -92 -55 -54 c-352 -344 -352 -905 -1 -1244 l56 -54 0
-95 c1 -232 91 -449 256 -614 163 -162 349 -243 589 -253 l120 -5 54 -55 c345
-349 897 -349 1242 0 l54 55 120 5 c143 6 246 31 362 89 219 109 391 319 454
553 13 51 23 124 26 200 l6 120 68 67 c125 122 213 287 246 463 18 96 13 257
-12 355 -38 147 -161 347 -264 429 l-41 33 0 96 c-1 483 -390 872 -873 872
l-91 0 -50 53 c-106 110 -252 197 -401 237 -78 21 -284 35 -345 24z m315 -328
c87 -34 147 -72 208 -134 l48 -48 -214 -500 c-176 -411 -217 -500 -234 -502
-18 -3 -42 51 -226 510 l-206 513 52 50 c108 102 238 148 402 141 77 -3 116
-10 170 -30z m-695 -792 c178 -443 201 -507 188 -520 -13 -13 -77 12 -521 202
l-507 217 0 57 c0 237 187 470 430 534 30 8 89 15 131 15 l77 1 202 -506z
m1405 491 c107 -27 184 -72 266 -154 114 -114 169 -244 169 -397 l0 -72 -506
-202 c-443 -178 -507 -201 -520 -188 -13 13 12 77 202 521 l217 507 57 0 c31
0 83 -7 115 -15z m-1837 -1092 c464 -199 483 -208 480 -232 -3 -23 -40 -40
-508 -228 l-505 -202 -53 56 c-152 163 -184 419 -80 630 38 78 144 197 167
188 9 -3 233 -99 499 -212z m2455 137 c102 -110 154 -253 144 -400 -9 -145
-64 -264 -170 -368 l-53 -53 -498 213 c-471 202 -497 214 -494 237 3 22 47 42
503 227 275 111 503 203 506 203 4 1 32 -26 62 -59z m-1453 -250 c45 -23 80
-80 80 -130 0 -17 -9 -49 -20 -70 -23 -45 -80 -80 -130 -80 -50 0 -107 35
-130 80 -25 50 -25 90 0 140 37 73 124 99 200 60z m-380 -462 c0 -5 -95 -232
-212 -504 l-211 -494 -51 0 c-139 1 -277 61 -387 170 -113 112 -169 242 -169
396 l0 72 498 199 c452 182 499 199 515 185 9 -8 17 -19 17 -24z m1158 -179
l492 -211 0 -57 c0 -123 -56 -260 -149 -362 -105 -117 -233 -177 -392 -186
l-96 -6 -200 501 c-183 457 -199 502 -185 516 9 9 21 16 27 16 5 0 232 -95
503 -211z m-620 -441 l201 -503 -55 -51 c-207 -194 -547 -189 -761 10 l-65 61
202 470 c111 259 206 480 211 493 6 15 17 22 37 22 28 0 30 -5 230 -502z'
		/>
	</g>
</svg>

export const MandarinIcon = (props: any) => <Icon component={MandarinSvg} {...props} />;


