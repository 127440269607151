import axios from 'backend/axios';
import { showError, showMessage } from 'utils/notifications';

const FileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const downloadTopSendersFile = (onFinish: () => void) => {
	axios.get('reports/excel/top-senders', { responseType: 'blob' }).then(response => {
		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		let loadFileName = 'Отчёт.xlsx';


		// eslint-disable-next-line max-len
		if (response.headers && Object.prototype.hasOwnProperty.call(response.headers, 'content-disposition')) {
			const fileNameStr = response.headers['content-disposition'] as string;

			const parsedFileNames = FileNameRegex.exec(fileNameStr);
			const [parsedFileName] = parsedFileNames || [];
			if (parsedFileName) {
				loadFileName = parsedFileName
					.replace('filename="', '')
					.replace('filename=', '')
					.replace('"', '');

				loadFileName = decodeURIComponent(loadFileName);
			}
		}

		link.setAttribute('download', loadFileName);
		document.body.appendChild(link);
		link.click();
		showMessage('Файл загружен');
		onFinish();
	}).catch(e => {
		showError('Не удалось скачать файл', e);
		onFinish();
	});
}
