import moment from 'moment';

export const IS_DEBUG = process.env.NODE_ENV === 'development';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:MM';


export const ADMIN_ROLES = ['SCOPE_admin_super', 'SCOPE_app_tangerine'];

export const PAGE_OUTER_CONTENT_HEIGHT =
	20 // паддинги вьюпорта
	+ 24 // паддинги карточки
	+ 42 // хэдер "Мандаринки"
	+ 21  //divider

//с 1 января закрываем вход
const APP_DATE_END = moment('12-01-2024 00:00:00', 'DD-MM-YYYY HH:mm:ss');

const now = moment();
export const LOGIN_ONLY_ADMINS = now.isSameOrAfter(APP_DATE_END) || now.format(DATE_FORMAT) === APP_DATE_END.format(DATE_FORMAT);
