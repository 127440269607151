import { useEffect, useRef, useState } from 'react';
import { Avatar, List } from 'antd';
import VirtualList from 'rc-virtual-list';
import { MessageService, PersonVO, ReactionResponse } from 'backend/services/tangerine';
import { showError } from 'utils/notifications';
import { dateTimeRenderer } from 'utils/utils';

const ListHeight = 150;
const PAGE_SIZE = 10;

export const LikedPeople = ({ messageId }: { messageId: number }) => {
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [total, setTotal] = useState(1);
	const [data, setData] = useState<ReactionResponse[]>([]);

	const scrollerRef = useRef(null);


	const appendData = () => {
		setLoading(true);
		MessageService.getReactionsByMessageId({
			pageNum: currentPage - 1,
			pageSize: PAGE_SIZE,
			id: messageId
		})
			.then(({ content, totalElements }) => {
				setLoading(false);
				setTotal(totalElements);
				setData(prev => {
					return currentPage === 1 && prev.length ? prev : prev.concat(content);
				})
			})
			.catch(e => {
				setLoading(false);
				showError('Не удалось загрузить список лайков');
			})
	};

	useEffect(() => {
		appendData();
	}, [currentPage]);

	const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
		if ((e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ListHeight) && data.length < total) {
			setCurrentPage(currentPage + 1);
		}
	}

	useEffect(() => {
		// @ts-ignore
		scrollerRef?.current?.scrollTo(0)
	}, [])

	return <List loading={loading}>
		<VirtualList
			data={data}
			ref={scrollerRef}
			height={ListHeight}
			itemHeight={28}
			itemKey='id'
			onScroll={onScroll}
		>
			{({ createDate = '', person = {} }: ReactionResponse) => (
				<List.Item key={person.id}>
					<List.Item.Meta
						avatar={<Avatar
							size='small'
							style={{ backgroundColor: person?.color }}
						>{person.shortName}</Avatar>}
						title={person.fullName}
					/>
				</List.Item>
			)}
		</VirtualList>
	</List>
};

export default LikedPeople;
