import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Tooltip, Tag } from 'antd'
import {GiftOutlined, HeartFilled, LoadingOutlined} from '@ant-design/icons';
import { FeedType } from 'views/feed/services';
import { FeedTypeProps, FeedTypes } from 'views/feed/filter/constants';
import styles from 'views/feed/filter/style.module.scss';
import { IUserData } from 'utils/types';
import { MandarinIcon } from 'icons/mandarin';
import {FeedTotals} from "../../../hooks/useTotalMessages";


interface FeedFilterFormProps {
	feedType: FeedType,
	onChangeFeedType: (feedType: FeedType) => void,
	onSendMessageClick: () => void,
	user: IUserData,
	totals: FeedTotals & {isLoading: boolean}
}


const FeedFilterForm = ({ feedType, onChangeFeedType, user, onSendMessageClick, totals }: FeedFilterFormProps) => {
	// const { user } = useContext(AuthContext);
	// const [feedType, setFeedType] = useState<FeedType>('ALL');

	const options = useMemo<FeedTypeProps[]>(() => {
		return FeedTypes.filter(({ isHidden }) => !isHidden || !isHidden(user));
	}, [user.deptPath]);

	return (
		<div className={styles.tangerine_toolbar}>
			<div className={styles.filter_tags}>
				{options.map(({ value, label, icon = null, tooltip }) =>
					<Tooltip
						title={tooltip}
						key={value}
					>
						<Tag.CheckableTag
							className={value}
							key={value}
							checked={feedType === value}
							onChange={(checked) => {
								// setFeedType(value as FeedType);
								onChangeFeedType(value as FeedType);
							}}
						>
							{icon}
							{totals.isLoading && <LoadingOutlined/>}
							{/*@ts-ignore*/}
							{!totals.isLoading && totals[value] > -1 && (<span className='total'>
								{/*@ts-ignore*/}
								{totals[value]}
							</span>) }
						</Tag.CheckableTag>
					</Tooltip>)
				}
				<Tooltip title='Поблагодарить коллегу'>
					<Button
						className={styles.send_button}
						type='primary'
						// icon={<MandarinIcon />}
						onClick={onSendMessageClick}
					>
						<div className={styles.btn_content}>
							<MandarinIcon />
							Поблагодарить
						</div>
					</Button>
				</Tooltip>
			</div>

		</div>
	)
}

export default FeedFilterForm;
