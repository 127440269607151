import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Checkbox, Modal, Tag, Tooltip, Typography} from 'antd';
import {LoadingOutlined, SendOutlined} from '@ant-design/icons';

import {AIService, PersonVO} from 'backend/services/tangerine';

import {sendTangerine} from 'views/feed/services';
import styles from 'views/feed/message/modal/styles.module.scss';
import {IUserData} from 'utils/types';
import RecipientSelector, {ChangeHandlerType} from './recipient-selector';
import TextareaWithEmojis from './message-field';
import {showError} from "../../../../utils/notifications";
import {MagicIcon} from "../../../../icons/magic";
import Loading from "../../../../components/loading";

interface TangerineFormProps {
    recipient?: PersonVO | null,
    closeModal: () => void,
    onSuccessSend: () => void;
    currentUser: any
}


const TangerineForm = ({closeModal, onSuccessSend, recipient = null, currentUser}: TangerineFormProps) => {
    const [recipients, setRecipients] = useState<PersonVO[]>(recipient ? [recipient] : []);
    const [message, setMessage] = useState('');
    const [isPrivate, setPrivate] = useState(false);
    const [toAll, setForAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const inputRef = useRef(null);

    const sendMessage = useCallback(() => {
        setLoading(true);
        const messageData = {
            from: currentUser,
            recipients: toAll ? [] : recipients,
            body: message,
            public: !isPrivate
        }
        sendTangerine(messageData, (isSuccess) => {
            setLoading(false);
            if (isSuccess) {
                onSuccessSend();
                closeModal();
            }
        })
    }, [recipients.length, message.length, toAll, isPrivate])

    const generateMessageByAI = useCallback(() => {
        setIsGenerating(true);
        AIService.chat()
            .then(generatedText => {
                setIsGenerating(false);
                setMessage(generatedText);
            })
            .catch(e => {
                setIsGenerating(false);
                showError('Не удалось сгенерировать поздравление', e);
            });
    }, [recipients.length, message.length, toAll, isPrivate])

    useEffect(() => {
        if (recipient) {
            // @ts-ignore
            inputRef.current?.focus();
        }
    }, []);

    return (
        <div
            className={`${styles.tangerine_form} ${isPrivate ? styles.private : ''}  ${!!recipient ? styles.is_reply : ''}`}
            id='tangerine-form'
        >
            <div className={`${styles.recipients}`}>
                {!recipient && <>
                    <div className={styles.otr_checkbox}>
                        <Tooltip title='Поздравить всю компанию'>
                            <Checkbox
                                className={'all_checkbox'}
                                checked={toAll}
                                onChange={({target: {checked}}) => {
                                    if (checked) {
                                        setPrivate(false);
                                    }
                                    setForAll(checked)
                                }}
                            >Всех</Checkbox>
                        </Tooltip>
                    </div>
                    <div style={{fontStyle: 'italic'}}>или</div>
                </>
                }
                <RecipientSelector
                    currentUserId={currentUser.id}
                    value={recipients}
                    toAll={toAll}
                    isPrivate={isPrivate}
                    defaultValue={recipients.map((user) => ({
                        label: user.fullName || '',
                        value: user.id || 0,
                        fullrecord: JSON.stringify(user)
                    }))}
                    onChange={(obj: ChangeHandlerType) => {
                        if (obj.hasOwnProperty('private')) {
                            setPrivate(obj.private as boolean);
                        }

                        if (obj.hasOwnProperty('users')) {
                            setRecipients(obj.users as PersonVO[]);
                        }
                    }}
                />
            </div>
            <TextareaWithEmojis
                inputRef={inputRef}
                disabled={isGenerating}
                value={message}
                setValue={setMessage}
            />
            <div className={styles.button_container} style={{paddingTop: 20}}>
                <Button
                    style={{marginRight: 16}}
                    onClick={generateMessageByAI}
                    // loading={isGenerating}
                    className={styles.magic_btn}
                    disabled={(!recipients.length && !toAll)}
                >
                    {isGenerating ? <LoadingOutlined/> : <MagicIcon className={styles.magic_icon}/>}
                    Сгенерировать поздравление
                </Button>
                <Button
                    type='primary'
                    onClick={sendMessage}
                    // loading={loading}
                    disabled={isGenerating || (!recipients.length && !toAll) || !message}
                >
                    {loading ? <LoadingOutlined/> : <SendOutlined/>}
                    Отправить
                </Button>
            </div>

        </div>
    )
}

export const showTangerineCreateModal = ({
                                             currentUser,
                                             receiver = null,
                                             onSuccessSend
                                         }: {
    onSuccessSend: () => void,
    currentUser: IUserData,
    receiver?: (PersonVO | null)
}) => {
    let modal: any = null;
    const closeModal = () => {
        modal?.destroy();
    }

    modal = Modal.info({
        width: 800,
        icon: null,
        title: 'Новая мандаринка',
        className: styles.tangerine_modal,
        closable: true,
        maskClosable: true,
        content: <TangerineForm
            recipient={receiver}
            closeModal={closeModal}
            onSuccessSend={onSuccessSend}
            currentUser={currentUser}
        />
    });
}
