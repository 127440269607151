import React from 'react';
import { Person, PiplService } from 'backend/services/pipl';
import DebounceSelect, { ISelectProps } from 'components/remote-select';
import { SelectProps } from 'antd/es/select';
import { ISelectOption } from 'utils/types';
import { shortFioRender } from 'utils/utils';


export async function fetchUserList(username: string): Promise<ISelectOption[]> {
	return PiplService.findByFullName({ fullName: username })
		.then((data: Person[]) => {
			return data.map(({ id, fullName, deptPath, email, positionName, telegram }) => ({
				label: shortFioRender({label: fullName}) || '',
				value: id || 0,
				dropdownValue: fullName,
				fullrecord: JSON.stringify({
					id,
					fullName,
					deptPath,
					email,
					positionName,
					telegram
				})
			}));
		});
}

const UserSelect = (props:  Omit<ISelectProps, 'loadData'>) => (
	<DebounceSelect {...props} loadData={fetchUserList} />
);

export default UserSelect;
