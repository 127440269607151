import React, {useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {Select, Typography} from 'antd';
import qs from 'qs';

import {SettingService} from 'backend/services/tangerine';
import InfiniteList from 'components/scrollable-list';
import AuthContext from 'context/auth-context';
import {IMessage} from 'utils/types';
import FeedFilterForm from './filter';
import {
    FeedType, isValidFeedType, loadTangerines as loadTangerinesList, TangerinesLoadResponse
} from './services';
import {IS_DEBUG, PAGE_OUTER_CONTENT_HEIGHT} from 'utils/constants';
import Message from './message/view';
import styles from './style.module.scss';
import {useTotalMessages} from "../../hooks/useTotalMessages";


const PAGE_SIZE = IS_DEBUG ? 5 : 20;

const getTabNameFromQueryParams = (queryStr: string): FeedType | null => {
    const queryParams = qs.parse(queryStr.replace('?', ''));
    return queryParams.tab && isValidFeedType(queryParams.tab as FeedType) ? queryParams.tab as FeedType : null;
}

const getArchiveContainerHeight = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight ||
        document.body.clientHeight;

     const height =
         windowHeight
         - PAGE_OUTER_CONTENT_HEIGHT
         - 52 //заголовок "Архив"
         - 50 // фильтр ленты

     console.log(`%c Archive height: ${height}`, 'color: magenta')
    return height

}

const ArchiveFeed = React.forwardRef((props, ref) => {
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const {user, loadUserStatistic} = useContext(AuthContext);

    const scrollableListRef = useRef(null);

    const [year, setYear] = useState(undefined);
    const [years, setYears] = useState([])
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [selectedFeedType, setFeedType] = useState<FeedType>(() => getTabNameFromQueryParams(location.search) || 'ALL');

    const [isLoadingTotals, totals] = useTotalMessages({
        userId: user.id || 0,
        year: year || 0,
        userDepartment: user.deptPath || ''
    });

    const yearOptions = useMemo(() => years.map(({id, name}) => <Select.Option key={id}
                                                                               value={id}>{name}</Select.Option>), [years.length]);
    const scrollToTop = useCallback(() => {
        // @ts-ignore
        scrollableListRef.current?.scrollToTop();
    }, [scrollableListRef.current]);

    const onLoadData = useCallback((loadResponse: TangerinesLoadResponse) => {
        const {isSuccess, content, totalElements: total, loadedPage} = loadResponse;

        setLoading(false);
        if (isSuccess) {
            setMessages(prev => {
                return loadedPage === 1 ? content : prev.concat(content as IMessage[]);
            });

            if (totalElements !== total) {
                setTotalElements(total);
            }
        }
    }, []);

    const loadTangerines = useCallback((pageNum = 0) => {
        if (!year) {
            return;
        }
        setLoading(true);
        if (pageNum > 0) {
            setCurrentPage(pageNum);
        }

        const pageToLoad = pageNum > 0 ? pageNum : currentPage;

        loadTangerinesList({
            year,
            pageNum: pageToLoad - 1,
            pageSize: PAGE_SIZE,
            currentUser: user,
            feedType: selectedFeedType
        }, onLoadData);
    }, [currentPage, selectedFeedType, year]);

    const onChangeFeedType = useCallback((newFeedType: FeedType) => {
        scrollToTop();
        setFeedType(newFeedType);
        setCurrentPage(1);
    }, [scrollToTop]);

    const loadMore = useCallback(() => {
        setCurrentPage(prevPage => prevPage + 1);
    }, []);

    useImperativeHandle(ref, () => ({
        onChangeFeedType
    }));

    useEffect(loadTangerines, [currentPage, selectedFeedType, year]);

    useEffect(() => {
        if (year) {
            setCurrentPage(1);
        }

    }, [year]);

    useEffect(() => {
        const newFeedType = getTabNameFromQueryParams(location.search);
        setSearchParams('');
        if (newFeedType && newFeedType !== selectedFeedType) {
            onChangeFeedType(newFeedType as FeedType);
        }
    }, [location.search, selectedFeedType]);

    useEffect(() => {
        setSearchParams('');
        SettingService.getAvailableYears()
            .then(availableYears => {
                setYears(availableYears.map((item: number) => ({
                    id: item,
                    name: `${item} г.`
                })));

                if (availableYears.length) {
                    setYear(availableYears[0]);
                }
            })
            .catch(e => {
                console.log(e);
            })
    }, []);

    return <div className={styles.archive}>
        <div className={styles.title}>
            <Typography.Title level={4}>Архив поздравлений</Typography.Title>
            <Select size='small' value={year} onChange={setYear} className={styles.year_select}>{yearOptions}</Select>
        </div>

        {(year || 0) > 0 && (
            <>
                <div className={styles.filter}>
                    <FeedFilterForm
                        user={user}
                        feedType={selectedFeedType}
                        onChangeFeedType={onChangeFeedType}
                        totals={{...totals, isLoading: isLoadingTotals}}
                    />
                </div>
                <InfiniteList
                    getHeight={getArchiveContainerHeight}
                    ref={scrollableListRef}
                    data={messages}
                    total={totalElements}
                    loading={loading}
                    loadMore={loadMore}
                    renderItem={(item: IMessage) => (
                        <Message
                            key={item.id}
                            highlightInboxMessages={!loading && selectedFeedType !== 'FOR_ME'}
                            message={item}
                            currentUser={user}
                        />
                    )}
                />
            </>
        )}

    </div>
});

export default ArchiveFeed;
