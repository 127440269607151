import { Avatar, Button, Popconfirm, Popover, Tooltip } from 'antd';
import { LikeResponse } from 'backend/services/tangerine';
import { ReplyIcon } from 'icons/reply';
import { HeartFilled, HeartOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import styles from 'views/feed/message/view/components/footer/style.module.scss';
import LikedPeople from 'views/feed/message/view/components/footer/liked-people';
import {dateTimeRenderer} from "../../../../../../utils/utils";

interface MessageFooterProps {
	className?: string;
	createDate: string,
	isDeleting: boolean;
	isLiking: boolean;
	likes: LikeResponse;
	messageId: number;
	onDeleteClick: (() => void) | null;
	onLikeClick: () => void;
	onReplyClick: (() => void) | null;
}

const MessageFooter = (props: MessageFooterProps) => {
	const {
		messageId, createDate, likes: { liked, number = 0, persons = [] }, className = '',
		onReplyClick, onLikeClick, onDeleteClick, isLiking, isDeleting
	} = props;

	const showAvatarsInLike = number > 0 && number < 4;

	const likeBtn = <Button
		className={`${styles.like_btn} ${liked ? styles.me_liked : ''}`}
		size='small'
		disabled={isLiking}
		onClick={onLikeClick}
	>
		<div className={styles.like_btn_content}>
			{isLiking ? <LoadingOutlined />
				: (liked ? <HeartFilled className='heart_filled' /> : <HeartOutlined />)}
			{/*<div className={`${styles.steam_container} ${isLiking ? styles.steaming : ''}`}>*/}
			{/*	{liked ? <HeartFilled className='heart_filled' /> : <HeartOutlined />}*/}
			{/*	<HeartFilled id='steam1' />*/}
			{/*	<HeartFilled id='steam2' />*/}
			{/*	<HeartFilled id='steam3' />*/}
			{/*	<HeartFilled id='steam4' />*/}
			{/*</div>*/}

			{showAvatarsInLike ?
				<Avatar.Group size='small'>
					{persons?.slice(0, 4).map(({ id, shortName, color }) => (
						<Avatar
							key={id}
							style={{ backgroundColor: color }}
						>{shortName}</Avatar>
					))}
				</Avatar.Group> : <span className={styles.liked_number}>{number}</span>}
		</div>
	</Button>;

	return (
		<div className={`${styles.actions} ${className}`}>
			<div className={styles.createDate}>{dateTimeRenderer(createDate, 'HH:mm DD.MM.YYYY')}</div>
			{number > 0 ? <Popover
				destroyTooltipOnHide={{ keepParent: true }}
				overlayClassName={styles.liked_people_popover}
				content={<LikedPeople messageId={messageId} />}
				trigger='contextMenu'
			>{likeBtn}</Popover> : likeBtn}
			{!!onReplyClick &&
				<Tooltip
					title='Поблагодарить в ответ'
					mouseEnterDelay={0.5}
				>
					<Button
						key='reply'
						size='small'
						className={styles.reply_btn}
						onClick={onReplyClick}
					>
						<ReplyIcon />
					</Button>
				</Tooltip>
			}
			{!!onDeleteClick && (
				<Popconfirm
					title='Удалить поздравление?'
					onConfirm={onDeleteClick}
				>
					<Button
						disabled={isDeleting}
						key='delete'
						size='small'
						className={styles.delete_btn}
					>
						{isDeleting ? <LoadingOutlined /> : <DeleteOutlined />}
					</Button>
				</Popconfirm>
			)}

		</div>
	)
}

export default MessageFooter;
