import { DATE_FORMAT, DATE_TIME_FORMAT } from 'utils/constants';
import moment from 'moment';
import { OptionProps } from 'rc-select/lib/Option';


export const getRandomId = () =>
	moment().unix() + Math.round(Math.random() * 10000) + Math.round(Math.random() * 100);

export const filterOption = (input: string, option: OptionProps | undefined) => {
	if (option && option.children) {
		const value = String(option.children).toLowerCase();
		return value.indexOf(input.toLowerCase()) >= 0;
	}
	return false;
}


export const dateRenderer = (date: typeof moment | string) => {
	let dateMoment = moment.isMoment(date) ? date : null;
	if (typeof date === 'string') {
		// @ts-ignore
		dateMoment = moment(date);
	}

	return dateMoment && dateMoment.isValid() ? dateMoment.format(DATE_FORMAT) : '';
}

export const dateTimeRenderer = (date: typeof moment | string, format = DATE_TIME_FORMAT) => {
	let dateMoment = moment.isMoment(date) ? date : null;
	if (typeof date === 'string') {
		// @ts-ignore
		dateMoment = moment(date);
	}

	return dateMoment && dateMoment.isValid() ? dateMoment.format(format) : '';
}


export const shortFioRender = ({ label = '' }: { label?: string }): string => {
	const nameParts = label.length ? label.split(/\s/) : [];
	let result = '';
	if (nameParts.length <= 1) {
		return label;
	}
	const [lastName, firstName] = nameParts;
	const secondName = nameParts.length > 2 ? nameParts[2] : ''
	result = lastName;
	if (firstName) {
		result = `${result} ${firstName.substring(0, 1)}.`
	}

	if (secondName) {
		result = `${result} ${secondName.substring(0, 1)}.`
	}

	return result;
}

export const lastFirstNameRender = (fio: string): string => {
	const nameParts = fio.length ? fio.split(/\s/) : [];
	let result = '';
	if (nameParts.length <= 1) {
		return fio;
	}

	const [lastName, firstName] = nameParts;
	return `${firstName} ${lastName}`;
}

export const getPassedTimeStr = (dateStr: string = '') => {
	if (!dateStr) {
		return '';
	}

	const date = moment.utc(dateStr);
	if (!date.isValid()) {
		return '';
	}

	// @ts-ignore
	const now = new moment();
	if (now.diff(date, 'seconds') < 60) {
		return '1мин';
	}

	const minutesDiff = now.diff(date, 'minutes');
	if (minutesDiff < 60) {
		return `${minutesDiff}мин`;
	}

	const hoursDiff = now.diff(date, 'hours');
	if (hoursDiff < 24) {
		return `${hoursDiff}ч`;
	}

	const daysDiff = now.diff(date, 'days');
	if (daysDiff < 7) {
		return `${daysDiff}д`;
	}
	return '';
}
