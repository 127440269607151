// @ts-ignore
import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom/client';
import './fonts/Blackberry.otf';
import './fonts/Brusnika.otf';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { IS_DEBUG } from 'utils/constants';

if (!IS_DEBUG) {
	console.log = () => {}
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
