import Icon from '@ant-design/icons';

const ReplySvg = () => <svg
	viewBox='0 0 32 32'
	width='1em'
	height='1em'
	fill='currentColor'
>
	{/*<path d='M 12.28125 5.28125 L 4.28125 13.28125 L 3.59375 14 L 4.28125 14.71875 L 12.28125 22.71875 L 13.71875 21.28125 L 7.4375 15 L 21 15 C 23.773438 15 26 17.226563 26 20 C 26 22.773438 23.773438 25 21 25 L 21 27 C 24.855469 27 28 23.855469 28 20 C 28 16.144531 24.855469 13 21 13 L 7.4375 13 L 13.71875 6.71875 Z' />*/}
	<path d='M28.88,30a1,1,0,0,1-.88-.5A15.19,15.19,0,0,0,15,22v6a1,1,0,0,1-.62.92,1,1,0,0,1-1.09-.21l-12-12a1,1,0,0,1,0-1.42l12-12a1,1,0,0,1,1.09-.21A1,1,0,0,1,15,4v6.11a17.19,17.19,0,0,1,15,17,16.34,16.34,0,0,1-.13,2,1,1,0,0,1-.79.86ZM14.5,20A17.62,17.62,0,0,1,28,26,15.31,15.31,0,0,0,13.91,12,1,1,0,0,1,13,11V6.41L3.41,16,13,25.59V21a1,1,0,0,1,1-1h.54Z' />
</svg>

export const ReplyIcon = (props: any) => <Icon component={ReplySvg} {...props} />;


