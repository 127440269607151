import { IUserData } from 'utils/types';
import { FeedType } from 'views/feed/services';
import {
	DownloadOutlined,
	HomeOutlined,
	UnorderedListOutlined,
	UploadOutlined,
	FireOutlined,
	HeartOutlined
} from '@ant-design/icons';
import { PeopleIcon } from 'icons/people';

export interface FeedTypeProps {
	icon?: JSX.Element;
	isHidden?: (user: IUserData) => boolean;
	label: string;
	tooltip: string;
	value: FeedType;
}

export const FeedTypes: FeedTypeProps[] = [
	{ label: 'Все', value: 'ALL', icon: <UnorderedListOutlined />, tooltip: 'Все' },
	{ label: 'Отправленные мной', value: 'FROM_ME', icon: <UploadOutlined />, tooltip: 'Отправленные мной' },
	{ label: 'Присланные мне', value: 'FOR_ME', icon: <DownloadOutlined />, tooltip: 'Присланные мне' },
	// {
	// 	label: 'Моего отдела',
	// 	value: 'MY_DEPARTMENT',
	// 	icon: <PeopleIcon />,
	// 	tooltip: 'Моего департамента',
	// 	// показываем эту вкладку только если у юзера не пустой департамент
	// 	isHidden: ({deptPath} : IUserData) => !deptPath
	// },
	{ label: 'Популярные', value: 'POPULAR', icon: <HeartOutlined />, tooltip: 'Популярные' }
];
