import {useCallback, useEffect, useState} from 'react';
import {FeedType} from "../views/feed/services";
import {useLoading} from "./useLoading";
import {FeedService} from "../backend/services/tangerine";
import {IMessage, ITangerinesRequestParams, IUserData} from "../utils/types";
import {IS_DEBUG} from "../utils/constants";

type TangerinesLoadResult = {
    content: IMessage[],
    totalElements: number
}

export type FeetTotalTypes = Exclude<FeedType, "TOP_SENDERS">
export type FeedTotals = Record<FeetTotalTypes, number>;

function getTotalResponse(response: PromiseSettledResult<TangerinesLoadResult>) {
    if (response.status !== 'fulfilled') {
        console.log(response.reason);
        return -1;
    }

    const {value: {totalElements = -1}} = response as PromiseFulfilledResult<TangerinesLoadResult>;
    return IS_DEBUG ? 9999 : totalElements;
}

export function useTotalMessages({userId, userDepartment, year}: {
    userId: number,
    userDepartment: string,
    year: number
}): [boolean, FeedTotals] {
    const [isLoading, showLoading, hideLoading] = useLoading(true);
    const [totals, setTotals] = useState<FeedTotals>({
        ALL: -1,
        FOR_ME: -1,
        FROM_ME: -1,
        MY_DEPARTMENT: -1,
        POPULAR: -1
    });

    useEffect(() => {
        if (!year || !userId) {
            return;
        }

        showLoading();
        const requestParams: ITangerinesRequestParams = {
            pageNum: 0,
            pageSize: 1,
            tangerineYear: year,
            body: {id: userId, dept: userDepartment}
        }

        Promise.allSettled([
            FeedService.all(requestParams),
            FeedService.dept(requestParams),
            FeedService.inbox(requestParams),
            FeedService.outbox(requestParams),
            FeedService.popular(requestParams),
        ]).then((results) => {
            const [all, dept, inbox, outbox, popular] = results;
            const newTotals: FeedTotals = {
                ALL: getTotalResponse(all),
                FOR_ME: getTotalResponse(inbox),
                FROM_ME: getTotalResponse(outbox),
                MY_DEPARTMENT: getTotalResponse(dept),
                POPULAR: getTotalResponse(popular)
            };

            setTotals(newTotals)
            hideLoading();
        });
    }, [year]);

    return [isLoading, totals];
}
