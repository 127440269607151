import { useMemo } from 'react';
import { Avatar, List } from 'antd';
import { IMessage, IUserData } from 'utils/types';
import {ArchiveMessageFooter} from './components/footer';
import {ArchiveMessageHeader} from './components/header';
import styles from './style.module.scss';


export interface TangerineItemProps {
	currentUser: IUserData;
	highlightInboxMessages: boolean;
	message: IMessage,
}

const Message = ({message,}: TangerineItemProps) => {
	const { from = {}, id: messageId = 0,isSentByMe, createDate, likes, showMine, timePassed } = message;

	const multilineMsg = useMemo(() => {
		const strings = message.body.split('\n');
		return <p>
			{strings.map((line: string) => <span key={line}>
				{line.replace('\n', '')}
				<br />
			</span>)}
		</p>
	}, [message.body.length]);

	return (
		<List.Item className={`${styles.list_item}  ${showMine ? styles.sent_to_me : ''}`}>
			<List.Item.Meta
				className={`${styles.list_item_content}`}
				avatar={
					<div className={styles.avatar_container}>
						<Avatar
							style={{ backgroundColor: from?.color }}
							className={isSentByMe ? styles.avatar_me : ''}
						>{from?.shortName}</Avatar>
						{timePassed && <div className={`${styles.create_date}`}>
							{timePassed}
						</div>}
					</div>
				}
				title={<ArchiveMessageHeader message={message} />}
				description={<div className={styles.tangerine_body}>
					<div className={styles.message}>{multilineMsg}</div>
					<ArchiveMessageFooter
						createDate={createDate}
						messageId={messageId}
						likes={likes}
						className={styles.message_actions}
					/>
				</div>}
			/>
		</List.Item>
	);
}

export default Message;
