import React from 'react';
import { Avatar, Statistic } from 'antd';
import { TopSenderResponse } from 'backend/services/tangerine';
import { UserPlaceComponent } from 'views/statistic/top-sender/place-icon';
import styles from './style.module.scss';


const TopSender = ({ sender: { count, person, place }, className = '' }: { className?: string, sender: TopSenderResponse }) => (
	<div className={`${styles.tangerine_body} ${className}`}>
		<UserPlaceComponent
			person={person}
			place={place}
		/>
		<div className={styles.avatar}>
			<Avatar style={{ backgroundColor: person.color }}>{person.shortName}</Avatar>
		</div>
		<div className={styles.person}>{person.displayName}</div>
		<div className={styles.count}>
			<span>{count}</span> <span style={{fontSize: '14px'}}>манд.</span>
			{/*<Statistic*/}
			{/*	value={count}*/}
			{/*	suffix={<img*/}
			{/*		width='14px'*/}
			{/*		height='14px'*/}
			{/*		style={{ filter: 'brightness(95%)' }}*/}
			{/*		src={`${process.env.PUBLIC_URL}/icon_mandarin.png`}*/}
			{/*	/>}*/}
			{/*/>*/}
		</div>
	</div>
);

export default TopSender;
