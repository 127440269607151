import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, List, Skeleton, Statistic, Typography } from 'antd';
import { DownloadOutlined, HeartFilled, LoadingOutlined } from '@ant-design/icons';
import { StatisticService, TopSenderResponse } from 'backend/services/tangerine';
import { showError } from 'utils/notifications';

import AuthContext from 'context/auth-context';
import TopSender from './top-sender';
import styles from './style.module.scss';
import InfiniteList from 'components/scrollable-list';
import { PAGE_OUTER_CONTENT_HEIGHT } from 'utils/constants';
import { downloadTopSendersFile } from 'views/statistic/services';


interface PaginatedContent<T> {
	content: T[];
	totalElements: number;
}

interface Totals {
	messages: number;
	likes: number;
}

interface TopResponse extends TopSenderResponse {
	isMe: boolean;
}

const getTopsContainerHeight = () => {
	const windowHeight = window.innerHeight || document.documentElement.clientHeight ||
		document.body.clientHeight;

	return windowHeight - PAGE_OUTER_CONTENT_HEIGHT
		- 66 // общая стата
		- 45 // заголовок "топ отправителей"
		- 66 // моя карточка
		- 12 //отступ снизу
}


const UserStatistic = () => {
	const {
		user: {
			id: userId = 0,
			tangerineProfile: { person },
			isAdmin,
			statistic: { placeByTopSender, numberOfMessagesSent }
		},
		loadUserStatistic
	} = useContext(AuthContext);

	const [isLoadingTotals, setLoadingTotals] = useState(false);
	const [isLoadingUserStat, setLoadingUserStat] = useState(false);
	const [isLoadingTops, setLoadingTops] = useState(false);
	const [isLoadingExcel, setLoadingExcel] = useState(false);
	const [page, setPage] = useState(1);

	const [topSenders, setTopSenders] = useState<PaginatedContent<TopResponse>>({
		content: [],
		totalElements: 0
	});

	const [totals, setTotals] = useState<Totals>({
		messages: 0,
		likes: 0
	});

	const loadNextPage = useCallback(() => {
		setPage(prev => {
			return prev + 1
		});
	}, []);

	useEffect(() => {
		console.log(`%c Page number: ${page}`, 'color: red');
	}, [page]);

	const loadTops = useCallback(() => {
		setLoadingTops(true);
		StatisticService.getStatistics({
			pageNum: page - 1,
			pageSize: 30
		})
			.then(({ content, totalElements }) => {
				setLoadingTops(false);
				setTopSenders(({ content: prev }) => {
					const convertedContent = content.map((item: TopSenderResponse) => ({
						...item,
						isMe: item.person.id === userId
					}));
					return {
						totalElements,
						content: page === 1 ? convertedContent
							: prev.concat(convertedContent)
					};
				})
			}).catch(e => {
			showError('Не удалось загрузить топ отправителей', e);
			setLoadingTops(false);
		})
	}, [page]);

	const downloadExcel = useCallback(() => {
		setLoadingExcel(true);
		downloadTopSendersFile(() => {
			setLoadingExcel(false);
		})
	}, []);

	useEffect(loadTops, [page]);

	useEffect(() => {
		setLoadingTotals(true);
		StatisticService.getTotalMessages()
			.then(({ numberOfLikes, numberOfMessages }) => {
				setTotals({
					messages: numberOfMessages,
					likes: numberOfLikes
				});

				setLoadingTotals(false);
			})
			.catch(e => {
				setLoadingTotals(false);
			});

		setLoadingUserStat(true);
		loadUserStatistic(() => {
			setLoadingUserStat(false);
		});
	}, []);

	return <div className={styles.statistic_view}>
		<div className={styles.total}>
			<Typography.Title
				level={5}
				style={{ color: 'rgba(20,20,20,0.9)', marginBottom: 4 }}
			>Статистика</Typography.Title>
			<div className={styles.totals}>
				<div className={styles.total_mandarines}>
					<Statistic
						loading={isLoadingTotals}
						value={totals.messages}
						suffix={<img
							width='20px'
							height='20px'
							style={{ marginBottom: 5 }}
							src={`${process.env.PUBLIC_URL}/icon_mandarin.png`}
						/>}
					/>
					<span className={styles.label}>отправлено</span>
				</div>
				<div className={styles.total_likes}>
					<Statistic
						loading={isLoadingTotals}
						value={totals.likes}
						suffix={<HeartFilled />}
					/>
					<span className={styles.label}>поставлено</span>
				</div>
			</div>
		</div>
		<Typography.Title
			level={5}
			style={{ margin: '8px 0 4px 0', fontSize: '15px', paddingLeft: 8 }}
		>&nbsp;Топ отправителей</Typography.Title>
		{isAdmin && <Button
			onClick={downloadExcel}
			className={styles.excel_btn}
		>
			{isLoadingExcel ? <LoadingOutlined /> : <DownloadOutlined />}
			Выгрузить в файл
		</Button>}
		<div style={{ padding: '8px 4px 8px 4px' }}>
			{isLoadingUserStat ? <Skeleton title={false} /> : <TopSender
				className={styles.my_card}
				sender={{
					person,
					place: placeByTopSender,
					count: numberOfMessagesSent
				}}
			/>}

		</div>
		<InfiniteList
			getHeight={getTopsContainerHeight}
			data={topSenders.content}
			total={topSenders.totalElements}
			loading={isLoadingTops}
			loadMore={loadNextPage}
			renderItem={(item: TopResponse) => (
				<List.Item className={item.isMe ? 'is_me' : ''}>
					<List.Item.Meta description={<TopSender sender={item} />} />
				</List.Item>
			)}
		/>
	</div>;
}

export default UserStatistic;
