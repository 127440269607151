import { useCallback, useMemo, useState } from 'react';
import { Avatar, List } from 'antd';

import { PersonVO } from 'backend/services/tangerine';
import { IMessage, IUserData } from 'utils/types';
import MessageFooter from 'views/feed/message/view/components/footer';
import MessageHeader from 'views/feed/message/view/components/header';
import styles from 'views/feed/message/view/style.module.scss';
import { deleteTangerine, likeTangerine } from 'views/feed/services';
import { IS_DEBUG } from 'utils/constants';

export interface TangerineItemProps {
	currentUser: IUserData;
	highlightInboxMessages: boolean;
	message: IMessage,
	onReplyClick: (to: PersonVO) => void;
	onLikesChange: (messageId: number, onFinishUpdate: () => void) => void;
	reloadFeed: () => void;
}

const Message = ({
	                 message,
	                 onReplyClick,
	                 reloadFeed,
	                 currentUser,
	                 onLikesChange
                 }: TangerineItemProps) => {
	const { from = {}, id: messageId = 0, body, likes, isSentToMe, isSentByMe, showMine, timePassed } = message;
	const [isDeleting, setIsDeleting] = useState(false);
	const [isLiking, setIsLiking] = useState(false);

	const onLikeButtonClick = useCallback(() => {
		setIsLiking(true);
		likeTangerine({
			messageId,
			user: currentUser
		}, (isSuccess) => {
			if (isSuccess) {
				onLikesChange(messageId, () => {
					setIsLiking(false);
				});
			} else {
				setIsLiking(false);
			}
		})
	}, [messageId]);

	const onDeleteButtonClick = useCallback(() => {
		if (currentUser.isAdmin) {
			setIsDeleting(true);
			deleteTangerine(currentUser.id || 0, messageId, (isSuccess) => {
				setIsDeleting(false);
				if (isSuccess) {
					reloadFeed();
				}
			})
		}
	}, [messageId, currentUser.isAdmin, currentUser.id]);

	const onReplyButtonClick = useCallback(() => {
		onReplyClick(from);
	}, [from.id]);


	const multilineMsg = useMemo(() => {
		const strings = message.body.split('\n');
		return <p>
			{strings.map((line: string) => <span key={line}>
				{line.replace('\n', '')}
				<br />
			</span>)}
		</p>
	}, [message.body.length]);

	return (
		<List.Item className={`${styles.list_item}  ${showMine ? styles.sent_to_me : ''}`}>
			<List.Item.Meta
				className={`${styles.list_item_content}`}
				avatar={
					<div className={styles.avatar_container}>
						<Avatar
							style={{ backgroundColor: from?.color }}
							className={message.isSentByMe ? styles.avatar_me : ''}
						>{from?.shortName}</Avatar>
						{timePassed && <div className={`${styles.create_date}`}>
							{timePassed}
						</div>}
					</div>
				}
				title={<MessageHeader message={message} />}
				description={<div className={styles.tangerine_body}>
					<div className={styles.message}>{multilineMsg}</div>
					<MessageFooter
						isLiking={isLiking}
						createDate={message.createDate}
						isDeleting={isDeleting}
						messageId={messageId}
						likes={likes}
						className={styles.message_actions}
						onReplyClick={isSentToMe || message.recipientType === 'TO_ALL' ? onReplyButtonClick : null}
						onDeleteClick={currentUser.isAdmin ? onDeleteButtonClick : null}
						onLikeClick={onLikeButtonClick}
					/>
				</div>}
			/>
		</List.Item>
	);
}

export default Message;
