import Icon from '@ant-design/icons';

// <?xml version="1.0" ?><svg class="feather feather-award" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
// 	<circle cx="12" cy="8" r="7"/><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"/>
// 	</svg>

const style = {
	stroke: 'currentColor',
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	strokeWidth: '2px'
}
// a = <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#4d4d4d;}</style></defs><title/>
// a = <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
	const AdminSvg = () => <svg
	viewBox='0 0 24 24'
	width='1em'
	height='1em'
	fill='white'
	// stroke='currentColor'
	// strokeLinecap='round'
	// strokeLinejoin='round'
	// strokeWidth={1}
>
	{/*@ts-ignore*/}
	<path d="M11.488 21.754c.294.157.663.156.957-.001 8.012-4.304 8.581-12.713 8.574-15.104a.988.988 0 0 0-.596-.903l-8.05-3.566a1.005 1.005 0 0 0-.813.001L3.566 5.747a.99.99 0 0 0-.592.892c-.034 2.379.445 10.806 8.514 15.115zM8.674 10.293l2.293 2.293 4.293-4.293 1.414 1.414-5.707 5.707-3.707-3.707 1.414-1.414z"/>
</svg>

	export const AdminIcon = (props: any) => <Icon component={AdminSvg} {...props} />;


