import Icon, {} from '@ant-design/icons';
import React from 'react';


const blackStyle: React.CSSProperties = {
	fill: 'none',
	stroke: 'white',
	strokeWidth: 16,
	strokeLinecap: 'round',
	strokeLinejoin: 'round'
}
const SecondPlaceSvg = () => <svg
	viewBox='0 0 256 256'
	width='1em'
	height='1em'
	fill='currentColor'
>

	<rect
		fill='none'
		height='256'
		width='256'
	/>
	<circle
		cx='128'
		cy='128'
		fill='none'
		r='96'
		style={{
			...blackStyle,
			fill: 'currentColor'
		}}
	/>
	<path
		d='M105.9,94.7A24,24,0,0,1,152,104a23.6,23.6,0,0,1-4.1,13.4h0L104,176h48'
		style={blackStyle}
	/>
</svg>

export const SecondPlaceIcon = (props: any) => <Icon component={SecondPlaceSvg} {...props} />;


