/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.loading,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class TemplateService {
  /**
   *
   */
  getTemplateById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: TemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  deleteTemplateById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  create(
    params: {
      /**  */
      template: TemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/templates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { template: params['template'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  updateYear(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getSettings(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getAvailableYears(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/archive/years';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MessageController {
  /**
   * Like message by id
   */
  like(
    params: {
      /**  */
      messageId: number;
      /** requestBody */
      body?: PersonVO;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages/{messageId}/like';
      url = url.replace('{messageId}', params['messageId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new message
   */
  createMessage(
    params: {
      /** requestBody */
      body?: MessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get message by id
   */
  getMessageById(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete message by id
   */
  deleteMessageById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get reactions by message id
   */
  getReactionsByMessageId(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages/{id}/reactions';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { pageNum: params['pageNum'], pageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get recipient types
   */
  getRecipientTypes(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messages/recipients/types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProfileService {
  /**
   * Create or update profile
   */
  upsertProfile(
    params: {
      /** requestBody */
      body?: ProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/profiles';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getProfile(
    params: {
      /**  */
      personId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/profiles/{personId}';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getStatistics1(
    params: {
      /**  */
      personId: number;
      /**  */
      tangerineYear?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/profiles/{personId}/statistics';
      url = url.replace('{personId}', params['personId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tangerineYear: params['tangerineYear'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FeedController {
  /**
   * Get most popular messages
   */
  popular(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feed/popular';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get feed
   */
  outbox(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feed/outbox';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get feed
   */
  inbox(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feed/inbox';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get feed
   */
  dept(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feed/dept';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get feed
   */
  all(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
      /** requestBody */
      body?: LoggedUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feed/all';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatisticsService {
  /**
   *
   */
  getStatistics(
    params: {
      /**  */
      pageNum?: number;
      /**  */
      pageSize?: number;
      /**  */
      tangerineYear?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/statistics/top-sender';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNum: params['pageNum'],
        pageSize: params['pageSize'],
        tangerineYear: params['tangerineYear']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  getTotalMessages(
    params: {
      /**  */
      tangerineYear?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/statistics/counted';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tangerineYear: params['tangerineYear'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReportControllerService {
  /**
   *
   */
  topSenders(
    params: {
      /**  */
      tangerineYear?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/excel/top-senders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { tangerineYear: params['tangerineYear'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReactionService {
  /**
   * Get reaction types
   */
  getReactionTypes(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reactions/types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class YaGptControllerService {
  /**
   *
   */
  chat(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/gpt/chat';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ActuatorService {
  /**
   * Actuator root web endpoint
   */
  links(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Actuator web endpoint 'health'
   */
  health(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator/health';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Actuator web endpoint 'health-path'
   */
  healthPath(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actuator/health/**';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   * Deactivate message
   */
  changeStatus(
    params: {
      /**  */
      userId: number;
      /**  */
      messageId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userId}/messages/{messageId}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{messageId}', params['messageId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface TemplateRequest {
  /**  */
  type?: EnumTemplateRequestType;

  /**  */
  body: string
}

export interface Template {
  /**  */
  id?: number;

  /**  */
  type?: EnumTemplateType;

  /**  */
  body: string

  /**  */
  createDate: string;
}

export interface SettingRequest {
  /**  */
  tangerineYear?: number;
}

export interface SettingResponse {
  /**  */
  id?: number;

  /**  */
  tangerineYear?: number;
}

export interface PersonVO {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  fullName?: string;

  /**  */
  deptPath?: string;

  /**  */
  shortName?: string;

  /**  */
  color?: string;

  /**  */
  displayName?: string;
}

export interface LikeResponse {
  /**  */
  messageId?: number;

  /**  */
  number?: number;

  /**  */
  liked?: boolean;

  /**  */
  persons?: PersonVO[];
}

export interface ProfileRequest {
  /**  */
  person: PersonVO;

  /**  */
  notificationOfNewMessage?: boolean;

  /**  */
  notificationOfReaction?: boolean;
}

export interface ProfileResponse {
  /**  */
  person: PersonVO;

  /**  */
  notificationOfNewMessage?: boolean;

  /**  */
  notificationOfReaction?: boolean;
}

export interface MessageRequest {
  /**  */
  from: PersonVO;

  /**  */
  recipients?: PersonVO[];

  /**  */
  groupName?: string;

  /**  */
  body: string

  /**  */
  public?: boolean;
}

export interface MessageResponse {
  /**  */
  id?: number;

  /** Just for the personal message. If the message is sent to the group or to all, then the field is always true */
  public?: boolean;

  /**  */
  from: PersonVO;

  /**  */
  recipientType?: EnumMessageResponseRecipientType;

  /**  */
  recipientTypeDescription?: string;

  /**  */
  recipients?: PersonVO[];

  /**  */
  groupName?: string;

  /**  */
  body: string

  /**  */
  likes: LikeResponse;

  /**  */
  active?: boolean;

  /**  */
  createDate: string;

  /**  */
  tangerineYear?: number;
}

export interface LoggedUserRequest {
  /**  */
  id?: number;

  /**  */
  dept?: string;
}

export interface PageMessageResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: MessageResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageableObject {
  /**  */
  paged?: boolean;

  /**  */
  unpaged?: boolean;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  offset?: number;

  /**  */
  sort?: SortObject;
}

export interface SortObject {
  /**  */
  unsorted?: boolean;

  /**  */
  sorted?: boolean;

  /**  */
  empty?: boolean;
}

export interface PageTopSenderResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: TopSenderResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface TopSenderResponse {
  /**  */
  person: PersonVO;

  /**  */
  count: number;

  /**  */
  place: number;
}

export interface TotalResponse {
  /**  */
  numberOfMessages?: number;

  /**  */
  numberOfLikes?: number;
}

export interface ReactionTypeResponse {
  /**  */
  type?: EnumReactionTypeResponseType;

  /**  */
  description?: string;
}

export interface ProfileStatistics {
  /**  */
  numberOfMessagesSent: number;

  /**  */
  numberOfMessagesReceived: number;

  /**  */
  placeByTopSender: number;
}

export interface PageReactionResponse {
  /**  */
  totalPages?: number;

  /**  */
  totalElements?: number;

  /**  */
  pageable?: PageableObject;

  /**  */
  numberOfElements?: number;

  /**  */
  size?: number;

  /**  */
  content?: ReactionResponse[];

  /**  */
  number?: number;

  /**  */
  sort?: SortObject;

  /**  */
  first?: boolean;

  /**  */
  last?: boolean;

  /**  */
  empty?: boolean;
}

export interface ReactionResponse {
  /**  */
  id?: number;

  /**  */
  messageId?: number;

  /**  */
  type?: EnumReactionResponseType;

  /**  */
  person: PersonVO;

  /**  */
  createDate: string;
}

export interface RecipientTypeResponse {
  /**  */
  type?: EnumRecipientTypeResponseType;

  /**  */
  description?: string;
}

export interface Link {
  /**  */
  href?: string;

  /**  */
  templated?: boolean;
}
export enum EnumTemplateRequestType {
  'NEW_MESSAGE' = 'NEW_MESSAGE',
  'LIKE' = 'LIKE'
}
export enum EnumTemplateType {
  'NEW_MESSAGE' = 'NEW_MESSAGE',
  'LIKE' = 'LIKE'
}
export enum EnumMessageResponseRecipientType {
  'PERSONAL' = 'PERSONAL',
  'GROUP' = 'GROUP',
  'TO_ALL' = 'TO_ALL'
}
export enum EnumReactionTypeResponseType {
  'LIKE' = 'LIKE',
  'LOVE' = 'LOVE',
  'FUNNY' = 'FUNNY',
  'WOW' = 'WOW',
  'HUG' = 'HUG'
}
export enum EnumReactionResponseType {
  'LIKE' = 'LIKE',
  'LOVE' = 'LOVE',
  'FUNNY' = 'FUNNY',
  'WOW' = 'WOW',
  'HUG' = 'HUG'
}
export enum EnumRecipientTypeResponseType {
  'PERSONAL' = 'PERSONAL',
  'GROUP' = 'GROUP',
  'TO_ALL' = 'TO_ALL'
}

export const MessageService = new MessageController();
export const FeedService = new FeedController();
export const UserService = new UsersService();
export const StatisticService = new StatisticsService();
export const TangerineProfileService = new ProfileService();
export const SettingService = new SettingsService();
export const AIService = new  YaGptControllerService();
	