import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Card, Divider, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import Loading from 'components/loading';
// import InfoFooter from 'components/info-footer';
import AuthContext from 'context/auth-context';
import { IUserDataShort, login } from 'backend/services/auth';
import { IAuthData, ICredentials, ServerError } from 'utils/types';
import { showError } from 'utils/notifications';

import styles from './style.module.scss';


const Login = (): JSX.Element => {
	const { onGetToken } = useContext(AuthContext);
	const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
	const [credentials, setCredentials] = useState<ICredentials>({ login: '', password: '' });

	// const isValidLogin = useMemo(() => {
	// 	return credentials.login && EMAIL_REGEX.test(credentials.login);
	// }, [credentials.login]);

	const isValidForm = useMemo(() => {
		return !!(credentials.login);
	}, [credentials.login, credentials.password]);

	const loginCallback = useCallback(() => {
		setIsLoggingIn(true);
		setTimeout(() => {
			const mailIndex = credentials.login.indexOf('@');
			let loginWithoutDomain = mailIndex > -1 ? credentials.login.slice(0, mailIndex) : credentials.login;
			login({
				login: loginWithoutDomain,
				// password: credentials.password
				password: 'NY2024'
			}, (isSuccess, data) => {
				setIsLoggingIn(false);
				if (isSuccess) {
					const loginData = data as IAuthData;
					onGetToken(loginData, true);
				} else {
					const serverError = data as ServerError;
					// В случае ошибки в data будет объект ошибки от бэка
					const { response: { data: error = '' } = { data: '' } } = serverError;
					// @ts-ignore
					showError('Не удалось авторизоваться', error);
				}
			});
		}, 1000);

	}, [credentials.password, credentials.login, onGetToken]);


	// @ts-ignore
	return (
		<div className={styles.login_container}>
			<Card
				className={styles.card}
				title={<div className={`lights ${isLoggingIn ? 'playing' : ''}`}>
					<ul className='lightrope'>
						{/*@ts-ignore*/}
						{[...Array(10).keys()].map(index => (
							<li
								key={index}
								className={`color-${(index % 3) + 1}`}
							/>
						))}
					</ul>
				</div>}
				onKeyDown={(ev) => {
					const isEnterKeyPressed = ev.keyCode === 13 || String(ev.key).toLowerCase() === 'enter';
					if (credentials.login && credentials.password && isEnterKeyPressed) {
						loginCallback();
					}
				}}
			>
				{/*//todo добавить красоты, лампочки*/}

				<div className={styles.content}>
					{/*{*/}
					{/*	isLoggingIn && <Loading text='Выполняем вход...' />*/}
					{/*}*/}
					<Input
						className={!credentials.login ? 'invalid' : ''}
						value={credentials.login}
						disabled={isLoggingIn}
						placeholder='Логин от рабочей учётной записи'
						prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
						onChange={({ target: { value: newLogin } }) => {
							setCredentials(prevCredentials => ({
								...prevCredentials,
								login: newLogin
							}))
						}}
					/>
					<div className={styles.hint}>логин от рабочей учётной записи</div>
					{/*<Input*/}
					{/*	placeholder='Пароль от рабочей учётной записи'*/}
					{/*	className={!credentials.password ? 'invalid' : ''}*/}
					{/*	type='password'*/}
					{/*	disabled={isLoggingIn}*/}
					{/*	value={credentials.password}*/}
					{/*	prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}*/}
					{/*	onChange={({ target: { value: newPassword } }) => {*/}
					{/*		setCredentials(prevCredentials => ({*/}
					{/*			...prevCredentials,*/}
					{/*			password: newPassword*/}
					{/*		}));*/}
					{/*	}}*/}
					{/*/>*/}
					{/*<div className={styles.hint}>пароль от рабочей учётной записи</div>*/}
					<div className={styles.button_container}>
						<Button
							className={styles.ok_button}
							disabled={!isValidForm || isLoggingIn}
							type='primary'
							onClick={loginCallback}
						>
							Войти
						</Button>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default Login;
