import {useMemo} from 'react';
import {Tooltip, Tag} from 'antd'
import {FeedType} from '../services';
import {FeedTypeProps, FeedTypes} from './constants';
import styles from './style.module.scss';
import {IUserData} from 'utils/types';
import {LoadingOutlined} from "@ant-design/icons";
import {FeedTotals} from "../../../hooks/useTotalMessages";


interface FeedFilterFormProps {
    feedType: FeedType,
    onChangeFeedType: (feedType: FeedType) => void,
    user: IUserData,
    totals: FeedTotals & {isLoading: boolean}
}


const FeedFilterForm = ({feedType, onChangeFeedType, user, totals}: FeedFilterFormProps) => {
    const options = useMemo<FeedTypeProps[]>(() => {
        return FeedTypes.filter(({isHidden}) => !isHidden || !isHidden(user));
    }, [user.deptPath]);

    return (
        <div className={styles.tangerine_toolbar}>
            <div className={styles.filter_tags}>
                {options.map(({value, label, icon = null, tooltip}) =>
                    <Tooltip
                        title={tooltip}
                        key={value}
                    >
                        <Tag.CheckableTag
                            key={value}
                            checked={feedType === value}
                            onChange={(checked) => {
                                // setFeedType(value as FeedType);
                                onChangeFeedType(value as FeedType);
                            }}
                        >
                            {icon}
                            {totals.isLoading && <LoadingOutlined/>}
                            {/*@ts-ignore*/}
                            {!totals.isLoading && totals[value] > -1 && (<span className='total'>
								{/*@ts-ignore*/}
                                {totals[value]}
							</span>) }
                        </Tag.CheckableTag>
                    </Tooltip>)
                }
            </div>

        </div>
    )
}

export default FeedFilterForm;
