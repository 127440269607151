import Icon, {} from '@ant-design/icons';
import React from 'react';


const blackStyle: React.CSSProperties = {
	fill: 'none',
	stroke: 'white',
	strokeWidth: 16,
	strokeLinecap: 'round',
	strokeLinejoin: 'round',
	// strokeMiterlimit: 10
}
const FirstPlaceSvg = () => <svg
	viewBox='0 0 256 256'
	width='1em'
	height='1em'
	fill='currentColor'
>
	<rect
		fill='none'
		height='256'
		width='256'
	/>
	<circle
		cx='128'
		cy='128'
		r='96'
		style={{
			...blackStyle,
			fill: 'currentColor'
		}}
	/>
	<polyline
		points='108 100 132 84 132 176'
		style={blackStyle}
	/>
</svg>

export const FirstPlaceIcon = (props: any) => <Icon component={FirstPlaceSvg} {...props} />;


