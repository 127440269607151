import React, { useCallback, useMemo, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Avatar } from 'antd';
import styles from './style.module.scss';
import useClickOutside from 'views/profile/useClickOutside';

export const hexToRGB = (hex: any): string => {
	let color = hex.startsWith('#') ? hex.replace('#', '') : hex;
	color = '0x' + color
	let r = color >> 16 & 0xFF
	let g = color >> 8 & 0xFF
	let b = color & 0xFF
	return `rgb(${r}, ${g}, ${b})`;
}

interface ColorSelectorProps {
	label: string,
	value: string,
	onChangeColor: (color: string) => void
}

export const ColorSelector = ({ label, value, onChangeColor }: ColorSelectorProps) => {
	const [isOpenPopover, togglePopover] = useState(false);
	const popover = useRef(null);

	const closePopover = useCallback(() => {
		togglePopover(false)
	}, []);

	const avatarColor = useMemo(() => value.startsWith('#') ? value : `#${value}`, [value]);

	useClickOutside(popover, closePopover);
	return (
		<div className={styles.color_selector}>
			<div>Цвет аватарки:</div>
			<Avatar
				style={{ backgroundColor: avatarColor }}
				onClick={() => {
					togglePopover(true);
				}}
			>
				{label}
			</Avatar>
			{isOpenPopover && (
				<div
					className={styles.color_popover}
					ref={popover}
				>
					<HexColorPicker
						color={value}
						onChange={onChangeColor}
					/>
				</div>
			)}
		</div>
	)
}

export default ColorSelector;
