import React, { useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss';



interface TangerinesListProps {
	data: any[],
	total: number,
	loading: boolean,
	loadMore: () => void,
	getHeight: () => number,
	renderItem: (item: any) => React.ReactNode;
}


const InfiniteList = React.forwardRef((props: TangerinesListProps, ref) => {
	const { data, total, loading, loadMore, renderItem, getHeight } = props;
	const scrollableListRef = useRef(null);
	const resizeTimeout = useRef(0);

	const [scrollableContainerHeight, setScrollableContainerHeight] = useState(getHeight());

	const scrollToTop = useCallback(() => {
		// @ts-ignore
		scrollableListRef.current?.el.scrollTo({ top: 0 });
	}, [scrollableListRef.current]);

	useImperativeHandle(ref, () => ({
		scrollToTop
	}), [scrollToTop]);

	const updateScrollerHeight = useCallback(() => {
		const newHeight = getHeight();
		console.log(`%cScroll height: ${newHeight}`, 'color: red');
		setScrollableContainerHeight(newHeight);
	}, []);

	useLayoutEffect(() => {
		function onResize() {
			clearTimeout(resizeTimeout.current);
			// @ts-ignore
			resizeTimeout.current = setTimeout(updateScrollerHeight, 200);
		}

		window.addEventListener('resize', onResize);
		updateScrollerHeight();
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return (
		<InfiniteScroll
			className={styles.infinite_scroll}
			height={scrollableContainerHeight}
			ref={scrollableListRef}
			dataLength={data.length}
			next={loadMore}
			hasMore={data.length < total}
			loader={loading ? <div className={styles.loader}>загрузка <LoadingOutlined /></div> : <div />}
			endMessage={loading ? <div /> : <div className={styles.scroll_end_placeholder}>
				<span style={{ lineHeight: '24px' }}>конец ленты сообщений</span>
			</div>}

		>
			<List
				size='small'
				loading={loading}
				bordered={false}
				dataSource={data}
				pagination={false}
				renderItem={renderItem}
			/>
		</InfiniteScroll>
	)
});

export default InfiniteList;
