import React from 'react';
import { PersonVO } from 'backend/services/tangerine';

import { FirstPlaceIcon } from 'icons/fisrt_place';
import { SecondPlaceIcon } from 'icons/second_place';
import { ThirdPlaceIcon } from 'icons/third_place';
import styles from './style.module.scss';

export const UserPlaceComponent = ({
	                              place
                              }: { className?: string, person: PersonVO, place: number }) => (
	<div className={styles.place}>
		{place === 1 && <FirstPlaceIcon className={styles.icon_first_place} />}
		{place === 2 && <SecondPlaceIcon className={styles.icon_second_place} />}
		{place === 3 && <ThirdPlaceIcon className={styles.icon_third_place} />}
		{place > 3 && <span className={styles.place_number}>{place}</span>}
	</div>
)

export default UserPlaceComponent;
