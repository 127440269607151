import React from 'react';
import { IAuthData, IUserData, IUserProfile } from 'utils/types';
import { ProfileStatistics } from 'backend/services/tangerine';

export const EmptyProfile: IUserProfile = {
	person: { id: 0 },
	colorHex: '',
	notificationOfNewMessage: false,
	notificationOfReaction: false
}

export const EmptyStatistic: ProfileStatistics = {
	placeByTopSender: 0,
	numberOfMessagesReceived: 0,
	numberOfMessagesSent: 0
}

export const EMPTY_USER: IUserData = {
	id: -1,
	isAdmin: false,
	userName: '',
	token: '',
	scope: '',
	tangerineProfile: { ...EmptyProfile },
	statistic: { ...EmptyStatistic }
};


interface IAuthContext {
	user: IUserData,
	isCheckingToken: boolean,
	isUserLoggedIn: boolean,
	logout: () => void,
	onGetToken: (props: IAuthData, showError: boolean) => void,
	loadTangerineProfile: (onLoad?: (profile: IUserProfile) => void) => void,
	loadUserStatistic: (onLoad?: () => void) => void,
	loadCurrentYear:() => void,

}

const AuthContext = React.createContext<IAuthContext>({
	isCheckingToken: false,
	isUserLoggedIn: false,
	user: {
		id: -1,
		isAdmin: false,
		scope: '',
		userName: '',
		token: '',
		tangerineProfile: { ...EmptyProfile },
		statistic: { ...EmptyStatistic }
	},
	loadTangerineProfile: () => {
	},
	loadUserStatistic: () => {
	},
	loadCurrentYear: () => {},
	logout: () => {
	},
	// @ts-ignore
	onGetToken: (props) => {
	},
});

export default AuthContext;
