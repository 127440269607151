import React, {useContext } from 'react';
import Loading from 'components/loading';
import AuthContext from 'context/auth-context';
import LoginView from 'views/login';
import { Result } from 'antd';
import AccessDeniedPage from 'views/access-denied-page';


const PrivatePage = ({ roles = [], children }: { children: JSX.Element|JSX.Element[], roles?: string[] }): JSX.Element => {
	const { isCheckingToken, isUserLoggedIn, user } = useContext(AuthContext);

	let element = <Loading />;

	if (!isCheckingToken) {
		if (!isUserLoggedIn) {
			element =  <LoginView />;
		}
		else {
			if (roles && roles.length) {
				const userHasAllRequiredRoles = roles.some(role => (user.roles || []).includes(role));
				// @ts-ignore
				element = userHasAllRequiredRoles ? children : <AccessDeniedPage />
			}
			else {
				// @ts-ignore
				element = children
			}
		}
	}

	return element
}


export default PrivatePage;
